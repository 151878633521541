import React, { useEffect, useState } from "react";
import "./DownloadPageContent.css";
import Example from './Carosuel'
import image from './001.jpg'
import image2 from './002.jpg'
import image3 from './003.jpg'




const items = [
  {
      image: image
  },
  {
      image: image2
  },
  {
    image: image3
}
]

const DownloadPageContent = () => {
  
  const extensionDownloadLink = "https://focii-analytics.s3.amazonaws.com/chrome-extension-mvp-release/FOCII_chrome_extension_mvp.zip"
  return (
    <div className="download-page-container">
      <div className="download-button-with-logo">
        <div>
          <h2 className="download-logo">FOCII</h2>
        </div>
        <div className="install-buttons">
          <div className="how-to-install">How to install?</div>
          <button className="download-button" onClick={()=>window.open(extensionDownloadLink,"_blank")}>Download FOCII Extension</button>
        </div>
      </div>
      <div className="overviewText">
        Earn BAAI tokens while watching YouTube videos on desktop google chrome
        browser. We use your engagement data to enable content creators in
        improving their craft, so that you can watch the best quality content
        online.
      </div>
      {/* <Carosuel /> */}
      <Example items={items}/>
      <div className="mvpOverview">
        <h3>Overview</h3>
        <ul>
          <li>
            This is a Minimum Viable Product. You will get to experience the
            Beta version very soon.
          </li>
          <li>
            Earn BAAI tokens while watching YouTube videos on desktop google
            chrome browser.
          </li>
          <li>
            We use your engagement data to enable content creators in improving
            their craft, so that you can watch the best quality content online.
          </li>
          <li>
            Key highlights: BAAI Rewards, Engage to earn, Engagement Analytics,
            AI/ML, Web3.
          </li>
          <li>
            To know more about FOCII and our reward program, kindly visit
            www.brainalive.ai
          </li>
        </ul>
      </div>
      <div className="mvpOverview">
        <h3>Key features:</h3>
        <ul>
          <li>Engagement Analytics</li>
          <li>BAAI Rewards</li>
          <li>
            Privacy Preserved
            <ul>
              <li>
                <a href="https://brainalive.ai/privacy" target="_blank">BrainAlive Ai</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="mvpOverview">
        <h3>Product Resources:</h3>
        <ul>
          <li>
            <a href="https://fociiplugin.brainalive.ai/chrome-extension-brochure">
              Brochure
            </a>
          </li>

          <li>
            <a href="https://fociiplugin.brainalive.ai/chrome-extension-release-note">
              Release Notes
            </a>
          </li>
        </ul>
      </div>
      <div className="mvpOverview">
        <h3>Privacy & Security:</h3>
        <ul>
          <li>
            We at BrainAlive are committed to protecting your privacy. The
            information we gather or process is used solely for core
            functionality of BrainAlive and to improve the quality and security
            of our service. Your information isn’t and has never been sold to
            third parties. For more information on privacy and the security of
            your data visit{" "}
            <a href="https://brainalive.ai/privacy">brainalive.ai/privacy</a>
          </li>
        </ul>
      </div>
      <div className="mvpOverview">
        <h3>Details:</h3>
        <ul>
          <li>Version: 0.1 - MVP</li>
          <li>Last updated: 29th Feb 2024</li>
          <li>
            Features: BAAI Rewards, Engage to earn, Engagement Analytics, AI/ML,
            Web3
          </li>
          <li>Size: 70 MB</li>
          <li>Language: English</li>
          <li>Developer - BrainAlive Research Pvt Ltd</li>
          <li>
            Website + email:{" "}
            <a href="https://brainalive.ai">www.brainalive.ai</a> |{" "}
            <a href="mailto:support@braina.live">support@braina.live</a>
          </li>
        </ul>
      </div>
      
    </div>
  );
};

export default DownloadPageContent;
