import React from "react";
import "./Publications.css";

const Publications = () => {
  const data = [
    {
      id: 1,
      title:
        "We have developed a new framework CLEFT for contextualised adaptive engagement.",
      description:
        "CLEFT: Contextualised Unified Learning of User Engagement in Video Lectures With Feedback",
      author:
        "SUJIT ROY, VISHAL GAUR, HAIDER RAZA, (Senior Member, IEEE),AND SHOAIB JAMEEL",
      link: "https://focii-analytics.s3.amazonaws.com/assets/brainalive/CLEFT_Contextualised_Unified_Learning_of_User_Engagement_in_Video_Lectures_With_Feedback+(2).pdf",
    },
    {
      id: 2,
      title:
        "our results demonstrate the feasibility of using oscillatory dynamics of MEG signals, particularly beta-band power decrements, for localizing cortical engagement during a set of tasks designed for BCI research that did not involve dynamic sensory stimuli overt behavioral responses.",
      description:
        "Mapping and decoding cortical engagement during motor imagery, mental arithmetic, and silent word generation using MEG",
      author:
        "Vahab Youssofzadeh, Sujit Roy, Anirban Chowdhury, Aqil Izadysadr, Lauri Parkkonen, Manoj Raghavan and Girijesh Prasad",
      link: "https://focii-analytics.s3.amazonaws.com/assets/brainalive/Human+Brain+Mapping+-+2023+-+Youssofzadeh+-+Mapping+and+decoding+cortical+engagement+during+motor+imagery++mental_compressed.pdf",
    },
    {
      id: 3,
      title:
        "The pilot trial presented in this paper introduces a novel neurorehabilitation protocol incorporating a separate active hand exoskeleton based PP followed by BCI based MP with multimodal neurofeedback.",
      description:
        "Active Physical Practice Followed by Mental Practice Using BCI-Driven Hand Exoskeleton: A Pilot Trial for Clinical Effectiveness and Usability",
      author:
        "Anirban Chowdhury, Student Member, IEEE, Yogesh Kumar Meena, Member, IEEE, Haider Raza, Member, IEEE, Braj Bhushan, Ashwani Kumar Uttam, Nirmal Pandey, Adnan Ariz Hashmi, Alok Bajpai Ashish Dutta, Member, IEEE, and Girijesh Prasad, Senior Member, IEEE",
      link: "https://focii-analytics.s3.amazonaws.com/assets/brainalive/Anirban_chowdhury2018(2).pdf",
    },
    {
      id: 4,
      title:
        "The study clearly demonstrates the feasibility of a novel challenge-based neurorehabilitation strategy using a fully wearable, portable and lightweight hand-exoskeleton, as the patients are able to operate at higher impedance level, requiring lesser assistance as the therapy sessions progress.",
      description:
        "Hand-Exoskeleton Assisted Progressive Neurorehabilitation Using Impedance Adaptation Based Challenge Level Adjustment Method",
      author:
        "Anirban Chowdhury , Student Member, IEEE, Shyam Sunder Nishad, Yogesh Kumar Meena , Member, IEEE, Ashish Dutta , Member, IEEE, and Girijesh Prasad , Senior Member, IEEE",
      link: "https://focii-analytics.s3.amazonaws.com/assets/brainalive/Anirban_chowdhury2018(1).pdf",
    },
    {
      id: 5,
      title:
        "This was the first study that investigated the application of AI to facilitate holistic PE investment decision-making.",
      description:
        "A Data-Driven Framework for Identifying Investment Opportunities in Private Equity",
      author:
        "Samantha Petersone, Alwin Tan, Richard Allmendinger, Sujit Roy,James Hales",
      link: "https://focii-analytics.s3.amazonaws.com/assets/brainalive/A+Data-Driven+Framework+for+Identifying+Investment+Opportunities+in+Private+Equity.pdf",
    },
    {
      id: 6,
      title: "Brain Machine interface facilitates neuroplasticity",
      description: "Brain–machine interfaces in neurorehabilitation of stroke",
      author:
        "Surjo R.Soekadar, Niels Birbaumer, Marc W.Slutzky, Leonardo G.Cohene",
      link: "https://www.sciencedirect.com/science/article/pii/S0969996114003714#bb0150",
    },
    {
      id: 7,
      title:
        "Current evidence suggests that Motor imagery provides additional benefits to conventional physiotherapy or occupational therapy",
      description:
        "Efficacy of motor imagery in post-stroke rehabilitation: a systematic review",
      author:
        "Andrea Zimmermann-Schlatter, Corina Schuster, Milo A Puhan, Ewa Siekierka & Johann Steurer",
      link: "https://link.springer.com/article/10.1186/1743-0003-5-8",
    },
    {
      id: 8,
      title:
        "Synchronizing stroke rehabilitation with voluntary neural and/or muscle activity can lead to motor recovery by targeting Hebbian plasticity. This reinforces the neural connections between paretic muscles and the residual motor area",
      description:
        "Combinations of stroke neurorehabilitation to facilitate motor recovery: perspectives on Hebbian plasticity and homeostatic meta plasticity",
      author: "Naoyuki Takeuch, Shin-Ichi Izumi",
      link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4477170/",
    },
    {
      id: 9,
      title:
        "Findings provide strong support for the reliability and potential rehabilitative application of MI-BCI in a stroke population, particularly in patients with mild to moderate motor impairments",
      description:
        "Is Motor-Imagery Brain-Computer Interface Feasible in Stroke Rehabilitation?",
      author: "Wei-Peng Teo, PhD, Effie Chew",
      link: "https://onlinelibrary.wiley.com/doi/abs/10.1016/j.pmrj.2014.01.006",
    },
    {
      id: 10,
      title:
        "Majority of stroke patients could use EEG-based motor imagery BCI",
      description:
        "A Large Clinical Study on the Ability of Stroke Patients to Use an EEG-based Motor Imagery Brain-Computer Interface",
      author:
        "Kai Keng Ang, Cuntai Guan, Karen Sui Geok Chua, Beng Ti Ang, Christopher Wee Keong Kuah, Chuanchu Wang, Kok Soon Phua, Zheng Yang Chin, Haihong Zhang",
      link: "https://journals.sagepub.com/doi/10.1177/155005941104200411",
    },
    {
      id: 11,
      title:
        "Rehabilitation paradigms that pair cortically-based intent with activation of affected circuits and positive neurofeedback appear to be required",
      description:
        "Enhancing Nervous System Recovery through Neurobiologics, Neural Interface Training, and Neurorehabilitation",
      author:
        "Max O Krucoff, Shervin Rahimpour, Marc Slutzky, Dennis A. Turner, Victor Edgerton",
      link: "https://www.researchgate.net/publication/311919389_Enhancing_Nervous_System_Recovery_through_Neurobiologics_Neural_Interface_Training_and_Neurorehabilitation",
    },
    {
      id: 12,
      title:
        "This systematic review suggests that BCI interventions may be a promising rehabilitation approach in subjects with stroke",
      description:
        "Use of Electroencephalography Brain-Computer Interface Systems as a Rehabilitative Approach for Upper Limb Function After a Stroke: A Systematic Review",
      author:
        "Esther Monge-Pereira, Jaime Ibañez-Pereda, Isabel M Alguacil-Diego, Jose I Serrano, María P Spottorno-Rubio, Francisco Molina-Rueda",
      link: "https://pubmed.ncbi.nlm.nih.gov/28512066/",
    },
    {
      id: 13,
      title:
        "Targeted Neuroplasticity Induced Through Operant Conditioning-modification of a behavior is induced by the consequence of that behavior",
      description:
        "Operant Conditioning of a Spinal Reflex Can Improve Locomotion after Spinal Cord Injury in Humans",
      author: "Aiko K. Thompson, Ferne R. Pomerantz, Jonathan R. Wolpaw",
      link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3579496/",
    },
    {
      id: 14,
      title:
        "This proof-of-principle study suggests that the combination of BCI training with goal-directed, active physical therapy may improve the motor abilities of chronic stroke patients despite apparent initial paralysis",
      description:
        "Combination of Brain-Computer Interface Training and Goal-Directed Physical Therapy in Chronic Stroke: A Case Report",
      author:
        "Doris Broetz, Christoph Braun, Cornelia Weber, Surjo R. Soekadar, Andrea Caria, Niels Birbaumer",
      link: "https://pubmed.ncbi.nlm.nih.gov/20519741/",
    },
    {
      id: 15,
      title:
        "The findings support the feasibility and justify the incorporation of home-based motor imagery exercises to improve walking skills for poststroke hemiparesis",
      description:
        "Home-Based Motor Imagery Training for Gait Rehabilitation of People With Chronic Poststroke Hemiparesis",
      author:
        "AyeletDunsky, RuthDickstein, Emanuel Marcovitz, SandraLevy, JudithDeutschPT",
      link: "https://www.sciencedirect.com/science/article/abs/pii/S0003999308003249",
    },
  ];
  return (
    <section className="primary-light-bg ">
      <div className="container">
        <div className="science-features-container-bottom">
          <h1 className="publication-heading  primary-dark-text text-center">
            Scientific Publications
          </h1>
          <div className="row">
            {data.map((data) => (
              <div className="col-lg-6">
                <a
                  className="publications-card secondary-light-bg"
                  href={data.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>
                    <p
                      style={{ fontWeight: 600 }}
                      className="primary-dark-text"
                    >
                      "{data.title}"
                    </p>
                    <p
                      id="publications-link-text"
                      className="primary-dark-text"
                    >
                      {data.description}
                    </p>
                  </div>
                  <div>
                    <small className="black-gray">{data.author}</small>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Publications;
