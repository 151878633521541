import videoManagement from "../../../assets/blogs/video-management.jpg";
import onlineTeaching from "../../../assets/blogs/online-teaching.jpg";
import onlineLearning from "../../../assets/blogs/online-learning.jpg";
import learningAnalytics from "../../../assets/blogs/learning-analytics.jpg";

export const blogData = [
  {
    id: 1,
    img: learningAnalytics,
    title: "Learning Analytics",
    postedBy: "BrainAlive",
    date: "12 months ago",
    fullDate: "November 13, 2021",
    link: "/blog/learn_analytics",
    tag: [{ tags: "Learning Analytics" }],
    blogReadMore:
      "As per an MIT study, the completion rates for LMS-based courses is not more than 4 per cent and the success of the graduates is even lower. Various factors that influence learner engagement include the quality of lesson design and delivery...",
    readMore:
      "As per an MIT study, the completion rates for LMS-based courses is not more than 4 per cent and the success of the graduates is even lower. Various factors that influence learner engagement include the quality of lesson design and delivery, it’s difficulty for students...",
    facebookShare:
      "https://web.facebook.com/sharer.php?t=Learning%20Analytics&u=https%3A%2F%2Fbraina.live%2Fblog%2F2021%2F11%2F13%2Flearning-analytics-2%2F&_rdc=1&_rdr",
    twitterShare:
      "https://twitter.com/intent/tweet?text=Learning+Analytics&amp;url=https%3A%2F%2Fbraina.live%2Fblog%2F2021%2F11%2F13%2Flearning-analytics-2%2F",
    linkedInShare:
      "https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fbraina.live%2Fblog%2F",
    copyLink: "https://braina.live/blog/2021/11/13/learning-analytics-2/",
  },
  {
    id: 2,
    img: videoManagement,
    title:
      "Is your educational video effectively managing the cognitive load of your viewers?",
    postedBy: "BrainAlive",
    date: "12 months ago",
    fullDate: "November 11, 2021",
    link: "/blog/video_managing",
    tag: [{ tags: "" }],
    blogReadMore:
      "The new normal in education is the ever increasing usage of online learning tools. Online learning stands to transform the concept of education with better content, improved accessibility, and affordability while meeting different learning needs...",
    readMore:
      "One of the primary considerations when constructing educational materials, including video, is cognitive load. Cognitive load...",
    facebookShare:
      "https://web.facebook.com/sharer.php?t=Is%20your%20educational%20video%20effectively%20managing%20the%20cognitive%20load%20of%20your%20viewers%3F&u=https%3A%2F%2Fbraina.live%2Fblog%2F2021%2F11%2F11%2Fis-your-educational-video-effectively-managing-the-cognitive-load-of-your-viewers%2F&_rdc=1&_rdr",
    twitterShare:
      "https://twitter.com/intent/tweet?text=Is+your+educational+video+effectively+managing+the+cognitive+load+of+your+viewers%3F&url=https%3A%2F%2Fbraina.live%2Fblog%2F2021%2F11%2F11%2Fis-your-educational-video-effectively-managing-the-cognitive-load-of-your-viewers%2F",
    linkedInShare:
      "https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fbraina.live%2Fblog%2F2021%2F11%2F11%2Fis-your-educational-video-effectively-managing-the-cognitive-load-of-your-viewers%2F",
    copyLink:
      "https://braina.live/blog/2021/11/11/is-your-educational-video-effectively-managing-the-cognitive-load-of-your-viewers/",
  },
  {
    id: 3,
    img: onlineTeaching,
    title: "Online teaching just got easier!",
    postedBy: "BrainAlive",
    date: "1 year ago",
    fullDate: "September 3, 2021",
    link: "/blog/online_teaching",
    tag: [
      { tags: "Content Engagement" },
      { tags: "Learner Engagement" },
      { tags: "Online Teaching" },
    ],
    blogReadMore:
      "As learning becomes increasingly online, the more challenging it becomes to deliver engaging, high quality and impactful education for students. While attention lapses have been a regular occurrence in the traditional classroom...",
    readMore:
      "As learning becomes increasingly online, the more challenging it becomes to deliver engaging, high quality and impactful education for students While attention lapses have been a regular occurrence in the traditional classroom...",
    facebookShare:
      "https://web.facebook.com/sharer.php?t=Online%20teaching%20just%20got%20easier!&u=https%3A%2F%2Fbraina.live%2Fblog%2F2021%2F09%2F03%2Fonline-teaching-just-got-easier%2F&_rdc=1&_rdr",
    twitterShare:
      "https://twitter.com/intent/tweet?text=Online+teaching+just+got+easier%21&url=https%3A%2F%2Fbraina.live%2Fblog%2F2021%2F09%2F03%2Fonline-teaching-just-got-easier%2F",
    linkedInShare:
      "https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fbraina.live%2Fblog%2F2021%2F09%2F03%2Fonline-teaching-just-got-easier%2F",
    copyLink:
      "https://braina.live/blog/2021/09/03/online-teaching-just-got-easier/",
  },
  {
    id: 4,
    img: onlineLearning,
    title:
      "Can online learning be made a fitting alternative to traditional classroom?",
    postedBy: "BrainAlive",
    date: "1 year ago",
    fullDate: "September 3, 2021",
    link: "/blog/online_learning",
    tag: [
      { tags: "New Normal" },
      { tags: "Online Teaching" },
      { tags: "Traditional Classroom" },
    ],
    blogReadMore:
      "The new normal in education is the ever increasing usage of online learning tools. Online learning stands to transform the concept of education with better content, improved accessibility, and affordability while meeting different learning needs...",
    readMore:
      " The new normal in education is the ever increasing usage of online learning tools. Online learning stands to transform the concept of education with better content improved accessibility",
    facebookShare:
      "https://web.facebook.com/sharer.php?t=Can%20online%20learning%20be%20made%20a%20fitting%20alternative%20to%20traditional%20classroom%3F&u=https%3A%2F%2Fbraina.live%2Fblog%2F2021%2F09%2F03%2Fcan-online-learning-be-made-a-fitting-alternative-to-traditional-classroom%2F&_rdc=1&_rdr",
    twitterShare:
      "https://twitter.com/intent/tweet?text=Can+online+learning+be+made+a+fitting+alternative+to+traditional+classroom%3F&amp;url=https%3A%2F%2Fbraina.live%2Fblog%2F2021%2F09%2F03%2Fcan-online-learning-be-made-a-fitting-alternative-to-traditional-classroom%2F",
    linkedInShare:
      "https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fbraina.live%2Fblog%2F2021%2F09%2F03%2Fcan-online-learning-be-made-a-fitting-alternative-to-traditional-classroom%2F",
    copyLink:
      "https://braina.live/blog/2021/09/03/can-online-learning-be-made-a-fitting-alternative-to-traditional-classroom/",
  },
];
