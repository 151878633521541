import React from "react";
import "./PopularArticles.css";
// import "../../BA3E/Ba3eHeader/Ba3eHeader.css";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  A11y,
  Autoplay,
  Thumbs,
  FreeMode,
} from "swiper";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";
import "swiper/scss/effect-creative";
import "swiper/css/thumbs";

const PopularArticles = ({ recentBlogs, oldBlog }) => {
  return (
    <div className="popular-article-bg d-flex justify-content-center secondary-light-bg">
      <div className="popular-article-container">
        <p className="popular-articles-heading primary-dark-text">
          Popular Articles
        </p>
        <div className="row main-swiper-width">
          <Swiper
            modules={[Navigation, Pagination, A11y, Autoplay, Thumbs, FreeMode]}
            autoplay={{ delay: 2000 }}
            loop
            navigation
            spaceBetween={0}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              480: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
              1440: {
                slidesPerView: 4,
              },
              1800: {
                slidesPerView: 5,
              },
              2560: {
                slidesPerView: 6,
              },
            }}
          >
            {recentBlogs?.map((data, index) => (
              <SwiperSlide key={index}>
                <div className="popular-article-div text-white mt-5 primary-light-bg">
                  <div className="card-number text-center ">
                    <p>{index + 1}</p>
                  </div>
                  <div className="d-flex align-content-between flex-wrap popular-card-content">
                    <div>
                      <div
                        className="read-more-text-overflow"
                        style={{ paddingTop: "3px" }}
                      >
                        <h4 className="popular-dynamic-article-card-titile black-gray">
                          {data.blogTitle}
                        </h4>
                        <p className="popular-read-more-text black-gray">
                          {data.blogContent[0].description}
                        </p>
                      </div>
                      <Link
                        className="text-decoration-none "
                        to={`/blog/${data.blogTitle.replace(/\s+/g, "_")}`}
                      >
                        <p
                          style={{ marginTop: "-20px" }}
                          className="blog-read-more"
                        >
                          Read more...
                        </p>
                      </Link>
                    </div>
                    <div className="">
                      <div className="d-flex date-div">
                        <p className="date">{data.date}</p>{" "}
                        <strong className="popular-dot">.</strong>{" "}
                        <p className="date">10 min read</p>
                      </div>
                      <div>
                        {data.tag.slice(0, 3).map((data) => (
                          <Link to={`/blogs/tag/${data.tags}`}>
                            {data.tags && (
                              <button className={`tag-btn-${index + 1}`}>
                                <small>{data.tags}</small>
                              </button>
                            )}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            {oldBlog?.map((data, index) => (
              <SwiperSlide key={index}>
                <div className="popular-article-div text-white mt-5 primary-light-bg">
                  <div className="card-number text-center">
                    <p>{index + 1 + oldBlog.length}</p>
                  </div>
                  <div className="d-flex align-content-between flex-wrap popular-card-content">
                    <div>
                      <div
                        className="read-more-text-overflow"
                        style={{ paddingTop: "3px" }}
                      >
                        <h4 className="popular-dynamic-article-card-titile black-gray">
                          {data.title}
                        </h4>
                        <p className="popular-read-more-text black-gray">
                          {data.readMore}
                        </p>
                      </div>

                      <Link
                        className="text-decoration-none"
                        to={`${data.link}`}
                      >
                        <p
                          style={{ marginTop: "-20px" }}
                          className="blog-read-more"
                        >
                          Read more...
                        </p>
                      </Link>
                    </div>
                    <div className="">
                      <div className="d-flex date-div">
                        <p className="date">{data.date}</p>{" "}
                        <strong className="popular-dot">.</strong>{" "}
                        <p className="date">10 min read</p>
                      </div>
                      <div>
                        {data.tag && (
                          <div>
                            {data.tag.map((data) => (
                              <Link to={`/blogs/tag/${data.tags}`}>
                                {data.tags && (
                                  <button className={`tag-btn-${index + 1}`}>
                                    <small>{data.tags}</small>
                                  </button>
                                )}
                              </Link>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default PopularArticles;
