import React, { useEffect } from "react";
import "../../css/style.css";
import "../../css/responsive.css";
import "./Home.css";
import HeaderBottom from "../../assets/header-bottom.png";
import Media1 from "../../assets/media-1.svg";
import Media2 from "../../assets/media-2.svg";
import Media3 from "../../assets/media-3.svg";
import Media4 from "../../assets/media-4.svg";
import Media5 from "../../assets/media-5.svg";
import Media6 from "../../assets/media-6.svg";
import Focci from "../../assets/FOCII.png";
import RightArrow from "../../assets/icon-li.svg";
import Arrow from "../../assets/arrow.svg";
import BA9E from "../../assets/BA9E.png";
import ABHA from "../../assets/Abha.png";
import Quet from "../../assets/quet.svg";
import Testtm from "../../assets/testm-pic.svg";
import Quet1 from "../../assets/qut-1.svg";
import Testtm1 from "../../assets/testm-2.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import MediaCoverage from "./MediaCoverage";
import monitor from "../../assets/monitor.png";

const Home = () => {
  const options = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: false,
    loop: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
        autoplay: true,
      },
      400: {
        items: 1,
        dots: true,
        autoplay: true,
      },
      600: {
        items: 1,
        dots: true,
        autoplay: true,
      },
      700: {
        items: 2,
        dots: false,
        autoplay: false,
      },
      1000: {
        items: 2,
      },
    },
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section>
      <div className="top-section-bg headerBg">
        <main>
          <div className="top-section">
            <h1>Boost Engagement With BAAI</h1>
            <p>
              BrainAlive AI (BAAI) is an AI Engine that leverages data
              transformation approach to convert raw interaction data into
              real-time user engagement insights. BAAI enables us to solve a
              whole range of real-world problems through our products
            </p>
            <Link to="/contact" className="btn btn-dark">
              {" "}
              Book a Demo
            </Link>
          </div>
          <div className="bottom-bg">
            <img src={HeaderBottom} alt="" />
          </div>
        </main>
        <div className="container-fluid px-0">
          <div className="media-logo  pt-5 ">
            <div className="logo-icon">
              <img src={Media3} alt="" />
            </div>
            <div className="logo-icon">
              <img src={Media2} alt="" />
            </div>

            <div className="logo-icon">
              <img src={Media4} alt="" />
            </div>
          </div>
          <div className="media-logo border-top-0 pb-5">
            <div className="logo-icon">
              <img src={Media5} alt="" />
            </div>
            <div className="logo-icon">
              <img src={Media6} alt="" />
            </div>
          </div>
        </div>

        <section className="innovation-video-bg">
          <div className="container">
            <h2>Meet BAAI: World's First Multi-Modal AI Engine Built To Quantify Human Engagement</h2>
            <div className="row m-0">
              <div className="video-responsive">
                <iframe
                  width="853"
                  height="480"
                  src="https://www.youtube.com/embed/iOCtS4F4-mU?autoplay=1&mute=1&rel=0"
                  allow="autoplay; encrypted-media;"
                  allowFullScreen
                  autoplay
                  title="Embedded youtube"
                />
              </div>
            </div>
          </div>
        </section>

        <div className="features aos-init aos-animate" data-aos="fade-up">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 order-lg-last aos-init aos-animate text-center"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <img src={Focci} className="img-fluid ipad-wth" alt="" />
              </div>
              <div
                className="col-lg-6 pt-4 aos-init aos-animate"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                <div className="mob-width">
                  <h3>FOCII</h3>
                  <p className="mb-0">
                    Discover Your Content’s Impact On Your Audience’s Attention,
                    Sentiment And Engagement.
                  </p>
                  <p> Know What Is Working And What Isn’t!</p>
                  <ul>
                    <li>
                      <i className="bi bi-check">
                        {" "}
                        <img src={RightArrow} />{" "}
                      </i>{" "}
                      Optimize you content delivery in real-time{" "}
                    </li>
                    <li>
                      <i className="bi bi-check">
                        <img src={RightArrow} />
                      </i>{" "}
                      Enhance your content with heatmaps and engagement scores
                    </li>
                    <li>
                      <i className="bi bi-check">
                        <img src={RightArrow} />
                      </i>
                      See how various metrics are related to overall audience
                      engagement
                    </li>
                    <li>
                      <i className="bi bi-check">
                        <img src={RightArrow} />
                      </i>
                      Improve customer satisfaction and retention
                    </li>
                  </ul>

                  <Link to="/focii" className="learn-more">
                    {" "}
                    Learn more about FOCII
                    <i className="bi bi-check">
                      <img src={Arrow} />
                    </i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="features aos-init aos-animate" data-aos="fade-up">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 aos-init aos-animate text-center"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <img src={BA9E} className="img-fluid ipad-wth" alt="" />
              </div>
              <div
                className="col-lg-6 pt-4 px-4 aos-init aos-animate"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                <h3>BCI</h3>
                <p>
                  Take A Peek Into Your Brain Activity. <br />
                  Gather, Analyse & Conclude. Explore Beyond Your Physical Self!
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check">
                      <img src={RightArrow} />
                    </i>
                    Monitor brain signals using our state of the art EEG
                    research headset
                  </li>
                  <li>
                    <i className="bi bi-check">
                      <img src={RightArrow} />
                    </i>{" "}
                    Our EEG headset comes with convenient software packages{" "}
                  </li>
                  <li>
                    <i className="bi bi-check">
                      <img src={RightArrow} />
                    </i>
                    Take a deep dive into exploring what lies beyond your
                    physical self
                  </li>
                  <li>
                    <i className="bi bi-check">
                      <img src={RightArrow} />
                    </i>
                    Discover & develop new habits that would help you blaze
                    through your day
                  </li>
                </ul>

                <Link to="/bci" className="learn-more">
                  {" "}
                  Learn more about BCI
                  <i className="bi bi-check">
                    <img src={Arrow} />
                  </i>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="features aos-init aos-animate" data-aos="fade-up">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 order-lg-last aos-init aos-animate text-center"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <img src={ABHA} className="img-fluid ipad-wth" alt="" />
              </div>
              <div
                className="col-lg-6 pt-4 aos-init aos-animate"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                <div className="mob-width">
                  <h3>ABHA</h3>
                  <p>
                    Relearn Your Forgotten Mobility, <br />
                    Accelerate Stroke Recovery!
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check">
                        <img src={RightArrow} />
                      </i>{" "}
                      Stroke rehabilitation therapy involves the user's brain
                      during the therapy
                    </li>
                    <li>
                      <i className="bi bi-check">
                        <img src={RightArrow} />
                      </i>
                      Helps achieve a better and quicker recovery
                    </li>
                    <li>
                      <i className="bi bi-check">
                        <img src={RightArrow} />
                      </i>
                      ABHA monitors your brain signals using EEG and captures
                      the imagination of your motor movement
                    </li>
                    <li>
                      <i className="bi bi-check">
                        <img src={RightArrow} />
                      </i>
                      Enables an upper limb exoskeleton in assisting the
                      mobility when the mental imagination is right
                    </li>
                  </ul>
                  <a href="#" className="learn-more"></a>
                  <Link to="/abha" className="learn-more">
                    Learn more about ABHA
                    <i className="bi bi-check">
                      <img src={Arrow} />
                    </i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Reward section */}
        <section className=" reward-section-container">
          <div className="container">
            <div className="row reward-section">
              <div className="col-lg-9">
                <img src={monitor} alt="" />
                <h1>Turn Your screen time to earn time with BAAI</h1>
                <p>Start earning while you connect, scroll, watch, or chat!</p>
              </div>
              <div className="col-lg-3 reward-explore-button">
                <a href="https://baai.co/" target="_blank">
                  EXPLORE
                </a>
              </div>
            </div>
          </div>
        </section>

        <MediaCoverage />

        {/* <div className="testimonial">
          <div className="container">
            <OwlCarousel
              {...options}
              className="basic-carousel owl-carousel owl-theme"
            >
              <div className="item">
                <div className="box-testimonial">
                  <img src={Quet} className="photo-qut" />
                  <img src={Testtm} alt="profile" className="photo" />
                  <p className="testimonial-text">
                    “The real-time insights provided by FOCII have transformed
                    the way I approach my craft. The analytics dashboard is a
                    treasure trove, offering a deep dive into how my audience
                    interacts with every word, sentence, and visual element.
                    It's like having a personal content strategist at my
                    fingertips.”
                  </p>
                  <p className="name">-Lisa Matter</p>
                </div>
              </div>
              <div className="item">
                <div className="box-testimonial">
                  <img src={Quet1} className="photo-qut-1" />
                  <img src={Testtm1} alt="profile" className="photo" />
                  <p className="testimonial-text">
                    “For the last 3 months, as a part of BrainAlive’s pilot
                    program, I’ve been using the BA9E product of BrainAlive. And
                    by using the BA9E I’ve discovered amazing insights on how my
                    brain interacts with different types of content and in
                    different situations. It was an amazing experience knowing
                    how much we can uncover by such powerful tool in hand.”
                  </p>
                  <p className="name">-Sandeep Sahu</p>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div> */}

        <div className="red-section">
          <div className="container">
            <div className="reasoly-box">
              <h2>Ready to grow your audience with better retention rate?</h2>
              <Link to="/contact" className="btn btn-dark">
                {" "}
                Book a Demo
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
