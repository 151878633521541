import React, { useEffect } from "react";
import "./index.css";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

const Index = () => {
  useEffect(() => {
    document.title = "Privacy | BrainAlive";
  }, []);
  return (
    <>
      <Navbar />
      <Container
        fluid
        className="privacy-margin-top px-0"
        style={{ textAlign: "justify" }}
      >
        <Container className="pb-4">
          <Row className=" heading m-0 p-0">
            <Col lg={12}>
              <div class="content-center">
                <h5 className="fw-bold">Brainalive’s Privacy Statement</h5>
                <br />
                <h6>Last updated: November 13, 2021</h6>
                <br />
                <p>
                  {" "}
                  This Privacy Statement describes the personal data we collect
                  and/or process (which may include collecting, organizing,
                  structuring, storing, using, or disclosing) to provide
                  products and BrainAlive products and its associated services
                  offered directly by BrainAlive Research Pvt. Ltd., including
                  BrainAlive’s websites and its meetings, webinars, and
                  messaging platform, and FOCII App Marketplace (“BrainAlive
                  Products” ,or “its Services”).
                </p>
                <br />
                <p>
                  <b>What Personal Data Do We Receive?</b>
                </p>
                <p>
                  Personal data is any information from or about an identified
                  or identifiable person, including information that BrainAlive
                  can associate with an individual person. We may collect, or
                  process on behalf of our customers, the following categories
                  of personal data when you use or interact with BrainAlive
                  Products:
                </p>
                <br />
                <ul>
                  <li>
                    <b>Account Information:</b> Information associated with an
                    account that licenses BrainAlive Products, which may include
                    administrator name, contact information, account ID, billing
                    information, and account plan information.
                  </li>
                  <li>
                    <b>Profile and Participant Information:</b> Information
                    associated with the BrainAlive profile of a user who uses
                    BrainAlive Products under a licensed account or that is
                    provided by an unlicensed participant joining a meeting,
                    which may include name, display name, picture, email
                    address, phone number, job information, stated locale, user
                    ID, or other information provided by the user or their
                    account owner.
                  </li>
                  <li>
                    <b>Contacts and Calendar Integrations:</b> Contact
                    information added by accounts or their users to create
                    contact lists on BrainAlive Products, which may include
                    contact information a user integrates from a third-party
                    app. Users can also integrate their calendars from other
                    BrainAlive products and its associated services with their
                    BrainAlive profile or account.
                  </li>
                  <li>
                    <b>Settings: </b> Information associated with the
                    preferences and settings on a BrainAlive account or user
                    profile, which may include audio and video settings,
                    recording file location, screen sharing settings, and other
                    settings and configuration information.
                  </li>
                  <li>
                    <b>Registration Information:</b> Information people provide
                    when registering for a BrainAlive meeting, webinar or
                    recording, which may include name and contact information,
                    responses to registration questions, and other registration
                    information requested by the host.
                  </li>
                  <li>
                    <b>Device Information :</b> Information about the computers,
                    phones, and other devices people use when interacting with
                    BrainAlive Products, which may include information about the
                    speakers, microphone, camera, OS version, hard disk ID, PC
                    name, MAC address, IP address (which may be used to infer
                    general location at a city or country level), device
                    attributes (like operating system version and battery
                    level), WiFi information, and other device information (like
                    Bluetooth signals).
                  </li>
                  <li>
                    <b>Meeting, Webinar, and Messaging Content and Context:</b>{" "}
                    Content generated in meetings, webinars, or messages that
                    are hosted on BrainAlive Products, which may include audio,
                    video, in-meeting messages, chat messaging content,
                    transcriptions, written feedback, responses to polls and
                    Q&amp;A, and files, as well as related context, such as
                    invitation details, meeting or chat name, or meeting agenda.
                    Content may contain your voice and image, depending on the
                    account owner’s settings, what you choose to share, your
                    settings, and what you do on BrainAlive Products.
                  </li>
                  <li>
                    <b>Product and Website Usage:</b> Information about how
                    people and their devices interact with BrainAlive Products,
                    such as: when participants join and leave a meeting; whether
                    participants sent messages and who they message with;
                    performance data; mouse movements, clicks, keystrokes or
                    actions (such as mute/unmute or video on/off), and other
                    user inputs that help BrainAlive to understand feature
                    usage, improve product design, and suggest features; which
                    third-party apps users add to a meeting or other Product and
                    what information and actions the app is authorized to access
                    and perform; use of third-party apps and the BrainAlive App
                    Marketplace; features used (such as screen sharing, emojis,
                    or filters); and other usage information and metrics. This
                    also includes information about when and how people visit
                    and interact with BrainAlive’s websites, including what
                    pages they accessed, their interaction with the website
                    features, and whether or not they signed up for a BrainAlive
                    Product.
                  </li>
                  <li>
                    <b>Communications with BrainAlive:</b> Information about
                    your communications with BrainAlive, including relating to
                    support questions, your account, and other inquiries.
                  </li>
                  <li>
                    <b>Information from Partners:</b> BrainAlive obtains
                    information about account owners and their users from
                    third-party companies, such as market data enrichment
                    BrainAlive products and its associated services, including
                    information about an account owner’s company size or
                    industry, contact information, or activity of certain
                    enterprise domains. BrainAlive may also obtain information
                    from third-party advertising partners who deliver ads
                    displayed on BrainAlive Products, such as whether you
                    clicked on an ad they showed you.
                  </li>
                </ul>
                <br />
                <p>
                  <b>How Do We Use Personal Data?</b>
                  <br /> BrainAlive employees do not access meeting, webinar, or
                  messaging content (specifically, audio, video, files, and
                  messages) unless directed by an account owner, or as required
                  for legal, safety, or security reasons, as discussed below.
                  BrainAlive uses personal data to conduct the following
                  activities:
                </p>
                <br />
                <ul>
                  <li>
                    <b>
                      Provide BrainAlive Products and BrainAlive products and
                      its associated services:
                    </b>{" "}
                    To provide Products, features, and BrainAlive products and
                    its associated services to account owners, their users, and
                    those they invite to join meetings and webinars hosted on
                    their accounts, including to customize BrainAlive Product
                    features and recommendations for accounts or their users.
                    BrainAlive also uses personal data, including contact
                    information, to route invitations and messages to recipients
                    when people send invitations and messages using BrainAlive
                    Products. This may also include using personal data for
                    customer support, which may include accessing audio, video,
                    files, and messages, at the direction of the account owner
                    or their users. We also use personal data to manage our
                    relationship and contracts with account owners, including
                    billing, compliance with contractual obligations, and
                    related administration.
                  </li>
                  <li>
                    <b>Product Research and Development:</b> To develop, test,
                    and improve BrainAlive Products, including, for example,
                    content-related features (such as background filters), and
                    to troubleshoot products and features.
                  </li>
                  <li>
                    <b>Marketing, Promotions, and Third-Party Advertising:</b>{" "}
                    To permit BrainAlive and/or its third party marketing
                    partners to market, advertise, and promote BrainAlive
                    Products, features, and its associated services, including
                    based on your product usage, information we receive from
                    third-party partners, or if you visit our websites,
                    information about how and when you visit, and your
                    interactions with them. We may also use cookies or similar
                    technology, including from third-party advertising partners,
                    to show you ads within BrainAlive Products and its
                    associated services, or causes from third parties.
                    BrainAlive does NOT use meeting, webinar, or messaging
                    content (specifically, audio, video, files, and messages)
                    for any marketing, promotions or third-party advertising
                    purposes.
                  </li>
                  <li>
                    <b>Authentication, Integrity, Security, and Safety:</b> To
                    authenticate accounts and activity, detect, investigate, and
                    prevent malicious conduct or unsafe experiences, address
                    security threats, protect public safety, and secure
                    BrainAlive Products.
                  </li>
                  <li>
                    <b>Communicate with You:</b> We use personal data (including
                    contact information) to communicate with you about
                    BrainAlive Products, features, and its associated services,
                    including product updates, your account, and changes to our
                    policies and terms. We also use your information to respond
                    to you when you contact us.
                  </li>
                  <li>
                    <b>Legal Reasons:</b> To comply with applicable law or
                    respond to valid legal process, including from law
                    enforcement or government agencies, to investigate or
                    participate in civil discovery, litigation, or other
                    adversarial legal proceedings, and to enforce or investigate
                    potential violations of our Terms of BrainAlive products and
                    its associated services or policies. BrainAlive uses
                    advanced tools to automatically scan content such as virtual
                    backgrounds, profile images, and files uploaded or exchanged
                    through chat, for the purpose of detecting and preventing
                    violations of our terms or policies and illegal or other
                    harmful activity, and its employees may investigate such
                    content where required for legal, safety, or security
                    reasons.
                  </li>
                </ul>
                <br />
                <p>
                  <b>How Do We Share Personal Data?</b>
                  <br />
                  BrainAlive provides personal data to third parties only with
                  consent or in one of the following circumstances (subject to
                  your prior consent where required under applicable law):
                </p>
                <ul>
                  <li>
                    <b>Resellers:</b> If an account owner licensed or purchased
                    BrainAlive Products from a third-party reseller of
                    BrainAlive Products, the reseller may be able to access
                    personal data and content for users, including meetings,
                    webinars, and messages hosted by the account owner.
                  </li>
                  <li>
                    <b>Vendors:</b> BrainAlive works with third-party apps and
                    its associated services providers to provide, support, and
                    improve BrainAlive Products and technical infrastructure,
                    and for business services such as payment processing.
                    BrainAlive may also work with third-party apps and its
                    associated services providers to provide advertisements and
                    business analytics regarding BrainAlive Products. These
                    vendors can access personal data subject to contractual and
                    technical requirements for protecting personal data and
                    prohibiting them from using personal data for any purpose
                    other than to provide BrainAlive products and its associated
                    services to BrainAlive or as required by law.
                  </li>
                  <li>
                    <b>For Legal Reasons:</b> BrainAlive may share personal data
                    as needed to: (1) comply with applicable law or respond to,
                    investigate, or participate in valid legal process and
                    proceedings, including from law enforcement or government
                    agencies; (2) enforce or investigate potential violations of
                    its Terms of BrainAlive products and its associated services
                    or policies; (3) detect, prevent, or investigate potential
                    fraud, abuse, or safety and security concerns, including
                    threats to the public; (4) meet our corporate and social
                    responsibility commitments; (5) protect our and our
                    customers’ rights and property; and (6) resolve disputes and
                    enforce agreements.
                  </li>
                  <li>
                    <b>Marketing, Advertising, and Analytics Partners: </b>{" "}
                    BrainAlive uses third-party marketing, advertising, and
                    analytics providers: to provide statistics and analysis
                    about how people are using BrainAlive Products and our
                    website; to provide advertising and marketing for BrainAlive
                    Products, including targeted advertising based on your use
                    of our website; and to show you third-party advertising
                    within BrainAlive Products. These third-party partners may
                    receive information about your activities on BrainAlive’s
                    website through third-party cookies placed on BrainAlive’s
                    website. To opt out of our use of third-party cookies that
                    share data with these partners, visit our cookie management
                    tool, available Cookies Settings. Where required by law,
                    BrainAlive will first obtain your consent before engaging in
                    the marketing or advertising activities described.
                  </li>
                  <li>
                    <b>Corporate Affiliates:</b> BrainAlive shares personal
                    information with corporate affiliates, to provide integrated
                    and consistent experiences across BrainAlive Products (such
                    as enabling a user to integrate a BrainAlive Phone call into
                    a meeting) to detect, investigate, and prevent fraud, abuse,
                    and threats to public safety.
                  </li>
                  <li>
                    <b>Change of Control:</b> We may share personal data with
                    actual or prospective acquirers, their representatives and
                    other relevant participants in, or during negotiations of,
                    any sale, merger, acquisition, restructuring, or change in
                    control involving all or a portion of BrainAlive’s business
                    or assets, including in connection with bankruptcy or
                    similar proceedings.
                  </li>
                </ul>
                <br />
                <p>
                  <b>
                    Who Can See and Share My Personal Data When I Use
                    BrainAlive?
                  </b>
                  <br /> When you send messages or join meetings and webinars on
                  BrainAlive, other people and organizations, including third
                  parties outside the meeting, webinar, or message, may be able
                  to see content and information that you share:
                </p>
                <br />
                <ul>
                  <li>
                    <b>
                      Account Owner: An account owner is the organization or
                      individual that signs up for a BrainAlive account.
                      Typically, an account owner designates one or more people
                      (called an “administrator”) to manage their account and
                      can grant privileges to users on the account. Depending on
                      their license with BrainAlive, the account owner can
                      authorize additional users on their account, and the
                      account owner can create and/or access the profile
                      information for all users on their account. The account
                      owner and their users can invite others (including guests
                      not on their account) to meetings or webinars hosted on
                      their account:
                    </b>{" "}
                    BrainAlive gives account owners controls and features that
                    they can use to determine whether certain types of content,
                    such as recordings or out-of-meeting messages, can be
                    created or sent, and what third-party apps can be used, for
                    meetings and webinars hosted on their account. Depending on
                    their settings, account owners and the people they designate
                    can access personal data for people who join meetings and
                    webinars on their account or send messages to users on their
                    account. Specifically, account owners may have access to:
                  </li>
                </ul>
                <br />
                <ul>
                  <li>
                    <b>Account Usage:</b>
                  </li>
                </ul>
                <ul>
                  <li>
                    <b>Product Usage:</b> Information about how people and their
                    devices interact with their account, which may include who
                    sent messages to their users in chat, email addresses, IP
                    addresses, device information, and other information about
                    who joined meetings or webinars on their account, whether
                    users viewed or downloaded a recording, how long people
                    participated in their meetings, the time a message was sent,
                    information about BrainAlive Phone integrations, and other
                    usage information and feedback metrics.
                  </li>
                  <li>
                    <b>Participant List:</b> Information about the participants
                    in a BrainAlive meeting, webinar, or chat, which may include
                    name, display name, email address, phone number, and
                    participant or user ID.
                  </li>
                </ul>
                <ul>
                  <li>
                    <b>Registration Information:</b> Information provided during
                    registration for a webinar, meeting, or recording hosted by
                    the account.
                  </li>
                  <li>
                    <b>BrainAlive Chat Out-of-Meeting Messages:</b> If enabled
                    on their account, account owners can see information about
                    who sent and received out-of-meeting messages to users on
                    their account along with information about the message (for
                    example, date and time, and number of participants).
                    Depending on their settings, account owners also can see
                    sender and receiver information, and other messaging data,
                    along with the content of messages sent to and from users on
                    their account, unless the account owner has enabled Advanced
                    Chat Encryption.
                  </li>
                  <li>
                    <b>In-Meeting/Webinar Messages: </b> Depending on their
                    settings, account owners can see sender and receiver
                    information, along with the content of messages sent to and
                    from users on their account, in the following circumstances:
                  </li>
                </ul>
                <ul>
                  <li>
                    Messages sent to Everyone in a meeting that is recorded
                  </li>
                  <li>
                    Messages sent to panelists in a webinar that is recorded
                  </li>
                  <li>
                    Direct messages if the account owner has enabled archiving
                  </li>
                </ul>
                <br />
                <ul>
                  <li>
                    <b>
                      Recordings: Account owners can watch the content of
                      recordings of meetings and webinars hosted on their
                      account. They can also view a transcript of meeting audio,
                      if enabled.
                    </b>
                  </li>
                  <li>
                    <b>Polling, Q&amp;A, and Feedback </b> Account owners can
                    see information about who provided responses to their polls,
                    Q&amp;A, or post meeting or webinar feedback requests,
                    including name and contact information, together with the
                    responses or feedback, unless responses are submitted
                    anonymously.
                  </li>
                </ul>
                <br />
                <ul>
                  <li>
                    <b>
                      Meeting Hosts and Participants: Hosts and other
                      participants in a meeting may be able to see your email,
                      display name, and profile picture. Meeting hosts and
                      participants can also see and (depending on the account
                      owner’s settings) record or save meeting content, audio
                      transcripts, messages sent to Everyone or to them
                      directly, and files, whiteboards, or other information
                      shared during a meeting. Hosts may also be able to see
                      responses to Q&amp;A and polls generated during the
                      meeting.
                    </b>
                  </li>
                  <li>
                    <b>
                      Webinar Panelists and Attendees: Only panelists may be
                      visible to attendees during a webinar, but attendees who
                      agree to unmute can be heard by other attendees. If an
                      attendee agrees to become a panelist during a webinar,
                      they may be visible to other attendees, depending on
                      settings. Panelists and attendees may be able to see the
                      name of a participant who asks a question during a
                      Q&amp;A, along with their question, unless the participant
                      submits the question anonymously.
                    </b>
                  </li>
                  <li>
                    <b>
                      Live Streams: Meeting and webinar hosts can choose to
                      livestream to a third-party site or services, which means
                      anyone with access to the livestream will be able to see
                      the meeting or webinar.
                    </b>
                  </li>
                </ul>
                <ul>
                  <li>
                    <b>Third-Party Apps and Integrations: </b>
                    <br />
                  </li>
                </ul>
                <ul>
                  <li>
                    Account owners can choose to add third-party apps to their
                    account and the BrainAlive Products they use, including via
                    use of the BrainAlive App Marketplace, and they can also
                    control whether their users can add and use specific
                    third-party apps, including in meetings, webinars, and chats
                    hosted on their account. Depending on their settings,
                    account owners and their users and guests can share your
                    personal data and content with third-party apps and
                    integrations they approve, which may include all of the
                    personal data available to account owners, hosts, and
                    participants listed above, such as account information,
                    profile and contact information, registration information,
                    participants list, settings, content, product usage, and
                    device information.{" "}
                  </li>
                  <li>
                    Other participants in the meeting may be able to see the
                    third-party app that you are using in a meeting, if the
                    third-party app is receiving real-time features and
                    information from the meeting.
                  </li>
                  <li>
                    Third-party developers may also integrate or embed
                    BrainAlive meetings into their website or app experiences or
                    build versions of BrainAlive that enable access to
                    BrainAlive Products from a third-party app.
                  </li>
                  <li>
                    Personal information shared by account owners and users with
                    third-party apps and integrations is collected and processed
                    in accordance with the app developers’ terms and privacy
                    policies, not BrainAlive’s.
                  </li>
                </ul>
                <br />
                <p>
                  <b>Privacy Rights and Choices</b>
                  <br /> If you are in the European Economic Area (EEA),
                  Switzerland, or the UK, or a resident of California, please
                  refer to the respective dedicated sections below. Otherwise,
                  at your request, and as required by applicable law, we will:
                </p>
                <ul>
                  <li>
                    Inform you of what personal data we have about you that is
                    under our control;
                  </li>
                  <li>
                    Amend or correct such personal data or any previous privacy
                    preferences you selected, or direct you to applicable tools;
                    and/or
                  </li>
                  <li>
                    Delete such personal data or direct you to applicable tools.
                    In order to exercise any of your rights as to personal data
                    controlled by BrainAlive, or if you have any other questions
                    about our use of your personal data, please send a request
                    to info@braina.live. Please identify yourself and, for
                    information relating to BrainAlive Products, specify your
                    account and/or user information and other information to
                    enable us to authenticate and respond to your request. Where
                    legally permitted, we may decline to process requests that
                    are unreasonably repetitive or systematic, require
                    disproportionate technical effort, or jeopardize the privacy
                    of others. As an account owner or a user under a licensed
                    account, you may also take steps to affect your personal
                    data by visiting your account and modifying your personal
                    data directly.
                  </li>
                </ul>
                <br />
                <p>
                  <b>Children</b>
                </p>
                <p>
                  BrainAlive does not allow children under the age of 14 to sign
                  up for a BrainAlive account for personal usage. For
                  educational organizations that use BrainAlive Products to
                  provide educational BrainAlive products and its associated
                  services to children under 14, can proceed to connect with the
                  team to understand more on BrainAlive’s Children’s Educational
                  Privacy Statement.
                </p>
                <br />
                <p>
                  <b>How to Contact Us</b>
                </p>
                <p>
                  To exercise your rights or if you have any privacy-related
                  questions or comments related to this Privacy Statement,
                  please send an email to{" "}
                  <a href="mailto:info@braina.live">info@braina.live.</a>
                </p>
                <p>
                  <b>
                    You can also contact us by writing to the following address:
                  </b>
                </p>
                <p>
                  BrainAlive Research Pvt Ltd <br />
                  Attention: <br />
                  14/144, 6th Floor, Ratan Esquire, <br />
                  Chunni Ganj, Kanpur, Uttar Pradesh India- 208001{" "}
                </p>
                <br />
                <br />
                <p>
                  Or to our representative in UK: <br />
                  Suite 2b, Crystal House <br />
                  New Bedford Road <br />
                  Luton, Bedfordshire <br />
                  United Kingdom LU1 1HS <br />
                  email: <a href="mailto:info@braina.live">info@braina.live</a>
                  <br />
                  You can also contact us by sending your message on our{" "}
                  <a href="https://brainalive.ai/contact">Contact us</a> page
                </p>
                <br />
                <p>
                  <b>Retention</b>
                </p>
                <p>
                  We retain personal data for as long as required to engage in
                  the uses described in this Privacy Statement, unless a longer
                  retention period is required by applicable law. <br />
                  <br />
                  The criteria used to determine our retention periods include
                  the following:
                </p>
                <ul>
                  <li>
                    The length of time we have an ongoing relationship with you
                    and provide BrainAlive Products to you (for example, for as
                    long as you have an account with us or keep using our
                    Products);
                  </li>
                  <li>
                    Whether account owners modify or their users delete
                    information through their accounts;
                  </li>
                  <li>
                    Whether we have a legal obligation to keep the data (for
                    example, certain laws require us to keep records of your
                    transactions for a certain period of time before we can
                    delete them); or
                  </li>
                  <li>
                    Whether retention is advisable in light of our legal
                    position (such as in regard to the enforcement of our
                    agreements, the resolution of disputes, and applicable
                    statutes of limitations, litigation, or regulatory
                    investigation).
                  </li>
                </ul>
                {/* <br /> */}
                Retention period of your data depends on its category:
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderRight: "1px solid #dee2e6",
                          width: "35%",
                        }}
                        scope="col"
                      >
                        User category
                      </th>
                      <th scope="col">Retention period</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th
                        style={{
                          borderRight: "1px solid #dee2e6",
                          color: "#141415",
                          fontWeight: 500,
                        }}
                        scope="row"
                      >
                        the Submitted Data
                      </th>
                      <td>
                        {" "}
                        until we cease directly or indirectly providing you with
                        the Services or until you delete your account, unless
                        and to the extent applicable law requires us to keep
                        some of the data for a longer period
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          borderRight: "1px solid #dee2e6",
                          fontWeight: 500,
                        }}
                        scope="row"
                      >
                        the Usage Data:
                      </th>
                      <td></td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          borderRight: "1px solid #dee2e6",
                          fontWeight: 500,
                        }}
                        scope="row"
                      >
                        IP-address
                      </th>
                      <td>until the end of the web session of the user</td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          borderRight: "1px solid #dee2e6",
                          fontWeight: 500,
                        }}
                        scope="row"
                      >
                        other data
                      </th>
                      <td>60 days</td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          borderRight: "1px solid #dee2e6",
                          fontWeight: 500,
                        }}
                        scope="row"
                      >
                        the Third-Party Data
                      </th>
                      <td>5 years</td>
                    </tr>
                  </tbody>
                </table>
                <p className="mt-5">
                  <b>Data Subjects Rights</b>
                </p>
                <p>
                  If you are in the EEA, Switzerland, India or the UK, your
                  rights in relation to your personal data processed by us as a
                  controller specifically include:
                </p>
                <br />
                <ul>
                  <li>
                    <b>Right of access and/or portability:</b> You have the
                    right to access any personal data that we hold about you
                    and, in some circumstances, have that data provided to you
                    so that you can provide or “port” that data to another
                    provider;
                  </li>
                  <li>
                    <b>Right of erasure:</b> In certain circumstances, you have
                    the right to the erasure of personal data that we hold about
                    you (for example, if it is no longer necessary for the
                    purposes for which it was originally collected);
                  </li>
                  <li>
                    <b>Right to object to processing:</b> In certain
                    circumstances, you have the right to request that we stop
                    processing your personal data and/or stop sending you
                    marketing communications;
                  </li>
                  <li>
                    <b>Right to rectification:</b> You have the right to require
                    us to correct any inaccurate or incomplete personal data;
                  </li>
                  <li>
                    <b>Right to restrict processing: </b> You have the right to
                    request that we restrict processing of your personal data in
                    certain circumstances (for example, where you believe that
                    the personal data we hold about you is not accurate or
                    lawfully held). In order to exercise any of your rights as
                    to personal data controlled by BrainAlive, or if you have
                    any other questions about our use of your personal data,
                    please send a request at the contact details specified in
                    the How to Contact Us section of this Privacy Statement.
                    Please note that we may request you to provide us with
                    additional information in order to confirm your identity and
                    ensure that you are entitled to access the relevant personal
                    da
                  </li>
                </ul>
                <br />
                <p>
                  <b>Legal Basis for Processing Personal Data</b>
                  <br /> We only use your information in a lawful, transparent,
                  and fair manner. Depending on the specific personal data
                  concerned and the factual context, when BrainAlive processes
                  personal data as a controller for individuals in regions such
                  as the EEA, Switzerland, India and the UK, we rely on the
                  following legal bases as applicable in your jurisdiction:
                </p>
                <br />
                <ul>
                  <li>
                    <b>As necessary for our contract:</b> When we enter into a
                    contract directly with you, we process your personal data on
                    the basis of our contract in order to prepare and enter into
                    the contract, as well as to perform and manage our contract
                    (i.e., providing BrainAlive Products, features and its
                    associated services to account owners, their users, and
                    those they invite to join meetings and webinars hosted on
                    their accounts, and manage our relationship and contract,
                    including billing, compliance with contractual obligations,
                    and related administration). If we do not process your
                    personal data for these purposes, we may not be able to
                    provide you with all Products, features, and its associated
                    services;
                  </li>
                  <li>
                    <b>As necessary to comply with our legal obligations:</b> We
                    process your personal data to comply with the legal
                    obligations to which we are subject for the purposes of
                    compliance with EEA and Indian laws, regulations, codes of
                    practice, guidelines, or rules applicable to us, and for
                    responses to requests from, and other communications with,
                    competent Indian and EEA public, governmental, judicial, or
                    other regulatory authorities. This includes detecting,
                    investigating, preventing, and stopping fraudulent, harmful,
                    unauthorized, or illegal activity (“fraud and abuse
                    detection”) and compliance with privacy laws;
                  </li>
                  <li>
                    <b>To protect your vital interests or those of others:</b>{" "}
                    We process certain personal data in order to protect vital
                    interests for the purpose of detecting and preventing
                    illicit activities that impact vital interests and public
                    safety, including child sexual abuse material; and
                  </li>
                  <li>
                    <b>
                      As necessary for our (or others’) legitimate interests,
                      unless those interests are overridden by your interests or
                      fundamental rights and freedoms, which require protection
                      of personal data:
                    </b>{" "}
                    We process your personal data based on such legitimate
                    interests to (i) enter and perform the contract with the
                    account owner and/or reseller providing you with the
                    Products (which includes billing, compliance with
                    contractual obligations, and related administration and
                    support); (ii) develop, test, and improve our Products and
                    troubleshoot products and features; (iii) ensure
                    authentication, integrity, security, and safety of accounts,
                    activity, and Products, including detect and prevent
                    malicious conduct and violations of our terms and policies,
                    prevent or investigate bad or unsafe experiences, and
                    address security threats; (iv) send marketing
                    communications, advertising, and promotions related to the
                    Products; and (v) comply with non-EEA and Indian laws,
                    regulations, codes of practice, guidelines, or rules
                    applicable to us and respond to requests from, and other
                    communications with, competent non-EEA and Indian public,
                    governmental, judicial, or other regulatory authorities, as
                    well as meet our corporate and social responsibility
                    commitments, protect our rights and property and the ones of
                    our customers, resolve disputes, and enforce agreements.
                  </li>
                </ul>
                <br />
                <p>
                  <b>International Data Transfers</b>
                  <br /> BrainAlive operates globally, which means personal data
                  may be transferred, stored (for example, in a data center),
                  and processed outside of the country or region where it was
                  initially collected where BrainAlive or its associated
                  services providers have customers or facilities – including in
                  countries where meeting participants or account owners hosting
                  meetings or webinars that you participate in or receiving
                  messages that you send are based. <br />
                  <br />
                  Therefore, by using BrainAlive Products or providing personal
                  data for any of the purposes stated above, you acknowledge
                  that your personal data may be transferred to or stored in the
                  United Kingdom and India where we are established currently,
                  as well as in other countries outside of the EEA, Switzerland,
                  India and the UK. Such countries may have data protection
                  rules that are different and less protective than those of
                  your country. <br />
                  <br />
                  We protect your personal data in accordance with this Privacy
                  Statement wherever it is processed and take appropriate
                  contractual or other steps to protect it under applicable
                  laws. Where personal data of users in the EEA, Switzerland,
                  India or the UK is being transferred to a recipient located in
                  a country outside the EEA, Switzerland, India or the UK which
                  has not been recognized as having an adequate level of data
                  protection, we ensure that the transfer is governed by the
                  Indian Commission’s standard contractual clauses. Please
                  contact us if you would like further information in that
                  respect.
                </p>
                <br />
                <p>
                  <b>California Privacy Rights</b>
                </p>
                <br />
                <p>
                  <b>California Consumer Privacy Act</b>
                  <br /> Under the California Consumer Privacy Act of 2018
                  (CCPA), California residents may have a right to:
                </p>
                <ul>
                  <li>
                    <b>Access: </b> the categories and specific pieces of
                    personal data BrainAlive has collected, the categories of
                    sources from which the personal data is collected, the
                    business purpose(s) for collecting the personal data, and
                    the categories of third parties with whom BrainAlive has
                    shared personal data;
                  </li>
                  <li>
                    <b>Delete: </b> personal data under certain circumstances;
                    and
                  </li>
                  <li>
                    <b>Opt out of the “sale”:</b> of personal data. We do not
                    sell your personal data in the conventional sense. However,
                    like many companies, we use advertising BrainAlive products
                    and its associated services that try to tailor online ads to
                    your interests based on information collected via cookies
                    and similar technologies about your online activity. This is
                    called interest-based advertising. The CCPA’s statutory
                    definition of the term “sale” is broad and may include
                    interest-based advertising. You can get more information and
                    opt out of the use of cookies on our sites for
                    interest-based advertising purposes by connecting with us
                    directly at info@braina.live. You will need to set your
                    preferences from each device and each web browser from which
                    you wish to opt out. This feature uses a cookie to remember
                    your preference, so if you clear all cookies from your
                    browser, you will need to reset your settings.
                  </li>
                </ul>
                <br />
                <p>
                  BrainAlive will not discriminate against you for exercising
                  any of these rights, which is further in line with your rights
                  under the CCPA. <br />
                  <br />
                  To exercise your rights, contact us by emailing
                  info@braina.live or calling +91-9811725502. To opt out of the
                  use of cookies on our sites for interest-based advertising
                  purposes, follow the instructions above. <br />
                  <br />
                  We will acknowledge receipt of your request within 10 business
                  days, and provide a substantive response within 45 calendar
                  days, or inform you of the reason and extension period (up to
                  90 days) in writing. <br />
                  <br />
                  Under the CCPA, only you or an authorized agent may make a
                  request related to your personal data. Note that to respond to
                  your requests to access or delete personal data under the
                  CCPA, we must verify your identity. We may do so by requiring
                  you to log into your BrainAlive account (if applicable),
                  provide information relating to your account (which will be
                  compared to information we have, such as profile information),
                  give a declaration as to your identity under penalty of
                  perjury, and/or provide additional information. You may
                  designate an authorized agent to submit your verified consumer
                  request by providing written permission and verifying your
                  identity, or through proof of power of attorney.
                </p>
                <br />
                <p>
                  <b>California’s Shine the Light Law</b>
                </p>
                <p>
                  California Civil Code Section 1798.83, also known as “Shine
                  The Light” law, permits California residents to annually
                  request information regarding the disclosure of your Personal
                  Information (if any) to third parties for the third parties’
                  direct marketing purposes in the preceding calendar year. We
                  do not share Personal Information with third parties for the
                  third parties’ direct marketing purposes.
                </p>
                <br />
                <br />
                <p>
                  <b>Changes to This Privacy Statement</b>
                  <br /> We may update this Privacy Statement periodically to
                  account for changes in our collection and/or processing of
                  personal data, and will post the updated Privacy Statement on
                  our website, with a “Last Updated” date at the top. If we make
                  material changes to this Privacy Statement, we will notify you
                  via email or on BAAI Dashboard and provide you an opportunity
                  to review before you choose to continue using our Products.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  );
};

export default Index;
