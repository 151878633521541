import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../../css/style.css";
import "../../css/responsive.css";
import bciHead from "../../assets/bci-head.png";
import bciCap from "../../assets/bci/BAAI-01.jpg";
import Media1 from "../../assets/media-1.svg";
import Media2 from "../../assets/media-2.svg";
import Media3 from "../../assets/media-3.svg";
import Media4 from "../../assets/media-4.svg";
import Media5 from "../../assets/media-5.svg";
import Media6 from "../../assets/media-6.svg";
import ba9e1 from "../../assets/ba9e1.png";
import ba9e2 from "../../assets/ba9e2.png";
import bluetooth from "../../assets/icon/bluetooth.svg";
import lightweight from "../../assets/icon/light-wt.svg";
import wireless from "../../assets/icon/wireless.svg";
import headset from "../../assets/icon/headset.svg";
import water from "../../assets/icon/water.svg";
import signal from "../../assets/icon/signal.svg";

import ba3e1 from "../../assets/ba3e1.png";
import ba3e2 from "../../assets/ba3e2.png";
import key1 from "../../assets/key1.png";
import key2 from "../../assets/key2.png";
import key3 from "../../assets/key3.png";
import key4 from "../../assets/key4.png";
import cap from "../../assets/cap.png";
import cap1 from "../../assets/bci/BAAI-1.png";
import cap2 from "../../assets/bci/BAAI-2.png";
import cap3 from "../../assets/bci/BAAI-3.png";
import cap4 from "../../assets/bci/BAAI-4.png";
import cap5 from "../../assets/bci/BAAI-5.png";
import ba3eHeadset from "../../assets/ba3e-headset.png";
import ba9eHeadset from "../../assets/ba9e-headset.png";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const BCI = () => {
  const bciSlider = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayTimeout: 2500,
    items: 1,
    loop: true,
    responsive: {},
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section>
      <div className="top-section-bg">
        <div className="abha-section">
          <div className="container">

            <div className="row align-items-center">

              <div className="col-lg-6 mt-md-5 mt-lg-0">
                <div className="">
                  <div className="smallFocii mt-0">
                    Brain Computer Interface
                  </div>
                  <h1>Peek Into Your Brain & Get To Know More</h1>
                  <p>Explore beyond your physical self.!</p>

                  <Link to="/contact" className="btn btn-dark">
                    {" "}
                    Book a Demo
                  </Link>
                </div>
              </div>

              <div className="col-lg-6">
                <div>
                  <OwlCarousel
                    {...bciSlider}
                    className="owl-carousel owl-theme"
                  >
                    <div className="item">
                      <img
                        src={bciHead}
                        className="img-fluid headsetImg"
                        alt=""
                      />
                    </div>
                    <div className="item">
                      <img
                        src={bciCap}
                        className="img-fluid headsetImg"
                        alt=""
                      />
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* section */}
      <div className="container-fluid px-0">
        <div className="media-logo pt-sm-5">
          <div className="logo-icon">
            <img src={Media3} />
          </div>
          <div className="logo-icon">
            <img src={Media2} />
          </div>

          <div className="logo-icon">
            <img src={Media4} />
          </div>
        </div>
        <div className="media-logo pb-sm-5">
          <div className="logo-icon">
            <img src={Media5} />
          </div>
          <div className="logo-icon">
            <img src={Media6} />
          </div>
        </div>
      </div>
      {/* section */}
      <div className="autumn electrode-f">
        <div>
          <nav>
            <div className="container">
              <div className="nav nav-tabs mb-3 " id="nav-tab" role="tablist">
                <button
                  className="nav-link active"
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-home"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  <span style={{ fontweight: 700 }}>BA9E</span> (9 Electrodes)
                </button>
                <button
                  className="nav-link"
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-profile"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  <span style={{ fontweight: 700 }}>BA3E</span> (3 Electrodes)
                </button>
              </div>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade active show"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div className="container">
                <div className="headset-card">
                  <div className="row">
                    <div className="col-lg-6">
                      <img src={ba9e1} className="img-fluid imageSet" alt="" />
                    </div>
                    <div className="col-lg-6">
                      <div className="headset-features row">
                        <div className="col-1 col-sm-1 px-0">
                          <div className="feature-logo">
                            <img src={bluetooth} alt="" />
                          </div>
                        </div>
                        <div className="col-11 col-sm-11">
                          <div className="features-content">
                            <h2>Better Connectivity Bluetooth 5.0</h2>
                            <p>
                              Seamless connectivity is yours with our advanced
                              Bluetooth tech. Enjoy extended range and swift
                              pairing for uninterrupted streaming and effortless
                              device control.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="headset-features row">
                        <div className="col-1 col-sm-1 px-0">
                          <div className="feature-logo">
                            <img src={lightweight} alt="" />
                          </div>
                        </div>
                        <div className="col-11 col-sm-11">
                          <div className="features-content">
                            <h2>Light Weight & Sleeker Design</h2>
                            <p>
                              The product is designed to be lightweight and
                              stylish. It features a sleek and modern design
                              that is both aesthetically pleasing and easy to
                              carry.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="headset-features row">
                        <div className="col-1 col-sm-1 px-0">
                          <div className="feature-logo">
                            <img src={wireless} alt="" />
                          </div>
                        </div>
                        <div className="col-11 col-sm-11">
                          <div className="features-content">
                            <h2>Non Invasive and Wireless</h2>
                            <p>
                              BA9E does not require any type of surgical or
                              invasive procedure. The non-invasive nature of
                              this product makes it suitable for people of all
                              ages over 6.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="headset-card">
                  <div className="row">
                    <div className="col-lg-6 order-lg-last text-right">
                      <img src={ba9e2} className="img-fluid imageSet" alt="" />
                    </div>
                    <div className="col-lg-6">
                      <div className="headset-features row">
                        <div className="col-1 col-sm-1 px-0">
                          <div className="feature-logo">
                            <img src={signal} alt="" />
                          </div>
                        </div>
                        <div className="col-11 col-sm-11">
                          <div className="features-content">
                            <h2>Amplified Signal Quality</h2>
                            <p>
                              The Amplified Signal Quality feature ensures clear
                              and accurate data transmission.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="headset-features row">
                        <div className="col-1 col-sm-1 px-0">
                          <div className="feature-logo">
                            <img src={water} alt="" />
                          </div>
                        </div>
                        <div className="col-11 col-sm-11">
                          <div className="features-content">
                            <h2>Sweat & Water Resistant</h2>
                            <p>
                              With an enhanced and user-friendly design, BA9E is
                              made for scalable and contextual human brain
                              research. It gives users access to
                              professional-grade brain data.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="headset-features row">
                        <div className="col-1 col-sm-1 px-0">
                          <div className="feature-logo">
                            <img src={headset} alt="" />
                          </div>
                        </div>
                        <div className="col-11 col-sm-11">
                          <div className="features-content">
                            <h2>Head Comfort</h2>
                            <p>
                              With an enhanced and user-friendly design, BA9E is
                              made for scalable and contextual human brain
                              research. It gives users access to
                              professional-grade brain data.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <div className="container">
                <div className="headset-card">
                  <div className="row">
                    <div className="col-lg-6">
                      <img src={ba3e1} className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6">
                      <div className="headset-features">
                        <div>
                          <h2>Average Focus Time</h2>
                          <p>
                            Get real time insights on your average focus for the
                            day.
                          </p>
                        </div>
                      </div>
                      <div className="headset-features">
                        <div>
                          <h2>Maximize Attention</h2>
                          <p>
                            Make informed decisions to identify which tasks
                            engage you the most and tune into the flow state.
                          </p>
                        </div>
                      </div>
                      <div className="headset-features">
                        <div>
                          <h2>Smart Zone Out</h2>
                          <p>
                            Block out notifications and alerts through
                            autonomous and smart diagnosis of your state of
                            focus.
                          </p>
                        </div>
                      </div>
                      <div className="headset-features">
                        <div>
                          <h2>Avoid Mental Weariness</h2>
                          <p>
                            Find out that recharge hack that lets you rejuvenate
                            best at the shortest possible time.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ba3e-features">
                  <div className="row justify-content-between">
                    <div className="col-lg-5 order-lg-2">
                      <img src={ba3e2} className="img-fluid" alt="" />
                    </div>
                    <div className="col-6 col-lg-3">
                      <div>
                        <h2>EEG</h2>
                        <p>Dry Electrode</p>
                      </div>
                      <div>
                        <h2>&lt;5uv</h2>
                        <p>System Noise (RFI)</p>
                      </div>
                      <div>
                        <h2>110 dB</h2>
                        <p>Common Mode Rejection Radio</p>
                      </div>
                    </div>

                    <div className="col-6 col-lg-3 order-lg-2">
                      <div>
                        <h2>24 Bits</h2>
                        <p>Resolution</p>
                      </div>
                      <div>
                        <h2>500</h2>
                        <p>Samples Per Second</p>
                      </div>
                      <div>
                        <h2>BT 5.0</h2>
                        <p>Latest Bluetooth Technology</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* section */}
      <div className="key-feature aos-init aos-animate" data-aos="fade-up">
        <div className="container">
          <h2>Key Features</h2>
          <div className="row">
            <div
              className="col-md-6 aos-init aos-animate"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <div className="cards me-sm-3">
                <img src={key1} className="img-fluid" alt="" />
                <div className="card-body">
                  <div className="key-head">
                    Simultaneous EEG And fNIRS Recording
                  </div>
                  <p>
                    EEG and fNIRS combined, have proven to give overall
                    increased classification accuracy and a better temporal as
                    well as spatial resolution. BrainAlive combines this
                    technology using dual-wavelength two-channel fNIRS and
                    8-channel EEG offering the highest signal fidelity to the
                    researchers.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 aos-init aos-animate"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <div className="cards ms-sm-3">
                <img src={key2} className="img-fluid" alt="" />
                <div className="card-body">
                  <div className="key-head">
                    Conductive Polymer-based Electrodes
                  </div>
                  <p>
                    BrainAlive BA9E uses conductive polymer Ag-AgCl electrodes
                    which enables you to obtain:
                  </p>
                  <ul>
                    <li>
                      Improved signal to noise ratio compared to regular comb
                      electrodes (metal).
                    </li>
                    <li>
                      Increased user comfort compared to metal electrodes.
                    </li>
                    <li>Electrode longevity and improved usage cycles.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 aos-init aos-animate"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <div className="cards me-sm-3">
                <img src={key3} className="img-fluid" alt="" />
                <div className="card-body">
                  <div className="key-head">Integrable SDK Environment</div>
                  <p>
                    BrainAlive offers APIs for softwares like neuro more and
                    Brain flow to be integrated with BA9E headset for data
                    acquisition, visualization, and analysis, offering a
                    seamless neuro research environment.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 aos-init aos-animate"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <div className="cards ms-sm-3">
                <img src={key4} className="img-fluid" alt="" />
                <div className="card-body">
                  <div className="key-head">
                    Promising EEG and fNIRS Signal Quality
                  </div>
                  <p>
                    BrainAlive’s state-of-the-art indigenously designed
                    electronics combined with precision sensors for EEG as well
                    as fNIRS offer research grade signal quality.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* section */}
      <div className="autumn key-feature">
        <div className="container">
          <h2>
            Unlock Your Mind's Potential With Our Cutting-Edge EEG Headsets
          </h2>
          <div className="headset-card d-none d-lg-block">
            <div className="ba9e-cap">
              <div className="col-md-7">
                <h4>BA9E-Cap</h4>
                <h6>9-Channel Wireless EED Headset</h6>
                <ul>
                  <li>
                    8 channel EEG: Conductive polymer-based active dry EEG
                    electrode system
                  </li>
                  <li>Dual channel, dual-wavelength fNIRS sensor</li>
                  <li>Flexible-positioning of the EEG electrodes</li>
                  <li>24-bit resolution at 500 Hz sampling rate for EEG</li>
                  <li>50 Hz sampling rate for fNIRS data</li>
                  <li>5 hours continuous EEG recording with fast charging</li>
                </ul>

                <Link to="/contact" className="btn btn-dark">
                  {" "}
                  Book a Demo
                </Link>
              </div>
              {/* image carousel */}
              <div className="col-md-5">
                <OwlCarousel
                  {...bciSlider}
                  className="headset-owl-carousel owl-theme"
                  style={{ width: "87.5%", borderRadius: "16px" }}
                >
                  <div
                    className="item"
                    style={{ background: "#F7F7F7", borderRadius: "10px" }}
                  >
                    <img src={cap1} className="img-fluid" alt="" />
                  </div>
                  <div
                    className="item"
                    style={{ background: "#F7F7F7", borderRadius: "10px" }}
                  >
                    <img src={cap2} className="img-fluid" alt="" />
                  </div>
                  <div
                    className="item"
                    style={{ background: "#F7F7F7", borderRadius: "10px" }}
                  >
                    <img src={cap3} className="img-fluid" alt="" />
                  </div>
                  <div
                    className="item"
                    style={{ background: "#F7F7F7", borderRadius: "10px" }}
                  >
                    <img src={cap4} className="img-fluid" alt="" />
                  </div>
                  <div
                    className="item"
                    style={{ background: "#F7F7F7", borderRadius: "10px" }}
                  >
                    <img src={cap5} className="img-fluid" alt="" />
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
          <div className="d-block d-lg-none ">
            <div className="row">
              <div className="col-sm-12">
                <div className="headset-card me-sm-3">
                  <div className="eeg-cap">
                    <div className="">
                      <OwlCarousel
                        {...bciSlider}
                        className="headset-owl-carousel owl-theme"
                      >
                        <div
                          className="item"
                          style={{
                            background: "#F7F7F7",
                            borderRadius: "10px",
                          }}
                        >
                          <img src={cap1} className="img-fluid" alt="" />
                        </div>
                        <div
                          className="item"
                          style={{
                            background: "#F7F7F7",
                            borderRadius: "10px",
                          }}
                        >
                          <img src={cap2} className="img-fluid" alt="" />
                        </div>
                        <div
                          className="item"
                          style={{
                            background: "#F7F7F7",
                            borderRadius: "10px",
                          }}
                        >
                          <img src={cap3} className="img-fluid" alt="" />
                        </div>
                        <div
                          className="item"
                          style={{
                            background: "#F7F7F7",
                            borderRadius: "10px",
                          }}
                        >
                          <img src={cap4} className="img-fluid" alt="" />
                        </div>
                        <div
                          className="item"
                          style={{
                            background: "#F7F7F7",
                            borderRadius: "10px",
                          }}
                        >
                          <img src={cap5} className="img-fluid" alt="" />
                        </div>
                      </OwlCarousel>
                    </div>
                    <h4>BA9E-Cap</h4>
                    <h6>9-Channel Wireless EED Headset</h6>
                    <Link to="/contact" className="btn btn-dark">
                      {" "}
                      Book a Demo
                    </Link>
                    {/* <button className="comingSoon">Coming Soon</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="headset-card me-sm-3">
                <div className="eeg-cap">
                  <img src={ba9eHeadset} className="w-100" alt="" />
                  <h4>BA9E-Headset</h4>
                  <h6>9-Channel Wireless EEG Headset</h6>
                  <button className="comingSoon">Coming Soon</button>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="headset-card ms-sm-3">
                <div className="eeg-cap">
                  <img src={ba3eHeadset} className="w-100" alt="" />
                  <h4>BA3E-Headset</h4>
                  <h6>3-Channel Wireless EEG Headset</h6>
                  <button className="comingSoon">Coming Soon</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="red-section">
        <div className="container">
          <div className="reasoly-box">
            <h2>Ready to know more about your brain activity?</h2>
            <Link to="/contact" className="btn btn-dark">
              {" "}
              Book a Demo
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BCI;
