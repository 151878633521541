import React, { useEffect } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import LearnAnalytic from "../../../components/Blogs/LearnAnalytics/LearnAnalytic";

const LearnAnalytics = () => {
  useEffect(() => {
    document.title = "Learning Analytics | Braina Live Blog";
  }, []);
  return (
    <>
      <Navbar />
      <LearnAnalytic />
      <Footer />
    </>
  );
};

export default LearnAnalytics;
