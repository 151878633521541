import React, { useEffect } from "react";
import "../../css/style.css";
import "../../css/responsive.css";
import AOS from "aos";
import "aos/dist/aos.css";
import abhaHead from "../../assets/abha-head.png";
import Media1 from "../../assets/media-1.svg";
import Media2 from "../../assets/media-2.svg";
import Media3 from "../../assets/media-3.svg";
import Media4 from "../../assets/media-4.svg";
import Media5 from "../../assets/media-5.svg";
import Media6 from "../../assets/media-6.svg";
import abha1 from "../../assets/abha1.png";
import abha2 from "../../assets/abha2.png";
import abha3 from "../../assets/abha3.png";
import abha4 from "../../assets/abha4.png";
import abha5 from "../../assets/abha5.png";
import { Link } from "react-router-dom";

const Abha = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section>
      <div className="top-section-bg abha-product d-flex justify-content-center align-items-center">
        <div className="abha-section ">
          <div className="container ">
            <div className="row w-100">
              <div className="col-lg-7">
                <div className="abha-padding">
                  <div className="smallFocii mt-0">
                    ABHA
                  </div>
                  <h1>
                    Harness The Power Of EEG & fNIRS For Stroke Rehabilitation
                  </h1>
                  <p>
                    Our active stroke therapy involves the user's brain during
                    the therapy to help achieve a better and quicker recovery
                  </p>
                  {/* <a className="btn btn-dark" href="#">
                    Book a Demo
                  </a> */}
                  <Link to="/contact" className="btn btn-dark">
                    Book a Demo
                  </Link>
                </div>
              </div>
              <div className="col-lg-5 mt-md-5 mt-sm-5 mt-lg-0">
                {/* <!-- <picture>
                <source media="(min-width:650px)" srcset="./img/focii-head.png">
                <source media="(min-width:465px)" srcset="./img/focii-head-mob.png">
                <img src="./img/focii-head-mob.png" alt="focii" className="img-fluid">
              </picture> --> */}
                <img src={abhaHead} className="img-fluid b-radius" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* section */}
      <div className="container-fluid px-0 pt-sm-5">
        <div className="media-logo pt-sm-5 mt-5">
          <div className="logo-icon">
            <img src={Media3} alt="" />
          </div>
          <div className="logo-icon">
            <img src={Media2} alt="" />
          </div>

          <div className="logo-icon">
            <img src={Media4} alt="" />
          </div>
        </div>
        <div className="media-logo pb-sm-5">
          <div className="logo-icon">
            <img src={Media5} alt="" />
          </div>
          <div className="logo-icon">
            <img src={Media6} alt="" />
          </div>
        </div>
      </div>
      {/* section */}
      <div className="container">
        <div className="mobility">
          <h2>Relearn Your Forgotten Mobility</h2>
          <p>
            Effective recovery is the first step in our mission. What we’re
            building together with doctors is shaping the mobility recovery of
            tomorrow. A system built on accessibility, affordability,
            transparency and collaboration, where rehabilitation robotics is no
            more restricted to just rehab centers, but also accessible from the
            comfort of your home. Recovery information flows freely between
            users and experts, where people get the right feedback to recover
            quickly.
          </p>
        </div>
      </div>

      <div className="audi-content aos-init aos-animate" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-6 aos-init aos-animate"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <img src={abha1} className="img-fluid" alt="" />
            </div>
            <div
              className="col-md-6 pt-4 px-4 aos-init aos-animate"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <h4>Quicker & Effective Rehabilitation</h4>
              <p>
                Right mental simulation of motor movement results in enhanced
                motor nerve activation, thus resulting in quicker and effective
                learning compared to repeated movement passive therapy
                techniques.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="audi-content aos-init aos-animate" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-6 order-md-last aos-init aos-animate"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <img src={abha2} className="img-fluid" alt="" />
            </div>
            <div
              className="col-md-6 pt-4 pe-sm-0 aos-init aos-animate"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <h4>Reduced Setup Time</h4>
              <p>
                Our proprietary machine learning algorithm uses transfer
                learning, thus eliminating the need to train the device for each
                new patient. This results in saving up to 30 mins per therapy.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="audi-content aos-init aos-animate" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-6 aos-init aos-animate"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <img src={abha3} className="img-fluid" alt="" />
            </div>
            <div
              className="col-md-6 pt-4 px-4 aos-init aos-animate"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <h4>100% Non-Invasive & Wireless</h4>
              <p>
                An easy to wear noninvasive EEG headset records the mental
                simulation of user's brain. The system comprises of a visual
                display, an EEG headset and an exoskeleton all connected
                wirelessly, thus making it easy to use.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="audi-content aos-init aos-animate" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-6 order-md-last aos-init aos-animate"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <img src={abha4} className="img-fluid" alt="" />
            </div>
            <div
              className="col-md-6 pt-4 pe-sm-0 aos-init aos-animate"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <h4>Remotely Accessible</h4>
              <p>
                The entire system is highly portable thus facilitating home
                based therapy. User performance is uploaded to the cloud for
                therapist consultation and review.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="audi-content aos-init aos-animate" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-6 aos-init aos-animate"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <img src={abha5} className="img-fluid" alt="" />
            </div>
            <div
              className="col-md-6 pt-4 px-4 aos-init aos-animate"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <h4>Enjoy Your Rehabilitation</h4>
              <p>
                The entire experience is gamified in a way that the user gets a
                real time 3D feedback of mental simulation accuracy, thus
                motivating the user for better engagement.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* section */}
      <div className="autumn">
        <div className="container">
          <h2>ABHA's Revolutionary Technology Uses</h2>
          <div className="row">
            <div className="col-sm-4">
              <div className="cards minus-margin">
                <h4>Artificial Intelligence</h4>
                <p>
                  ABHA knows to identify if the right zone of the brain is used
                  for imagining the mobility.
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="cards minus-margin">
                <h4>Electroencephalography (EEG)</h4>
                <p>
                  ABHA monitors your brain signals using EEG to capture the
                  imagination of your motor movement.
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="cards minus-margin">
                <h4>Assistive Exoskeleton</h4>
                <p>
                  ABHA uses an upper limb exoskeleton to assist the mobility
                  when the mental imagination is right.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="red-section pt-5">
        <div className="container">
          <div className="reasoly-box">
            <h2>Ready to relearn your forgotten mobility?</h2>
            {/* <a href="#" className="btn btn-dark">
              Book a Demo
            </a> */}
            <Link to="/contact" className="btn btn-dark">
              {" "}
              Book a Demo
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Abha;
