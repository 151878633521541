import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import TagRelatedBlogs from "../../../components/Blogs/TagRelatedBlogs/TagRelatedBlogs";

const DynamicTagBlogs = () => {
  return (
    <>
      <Navbar />
      <TagRelatedBlogs/>
      <Footer />
    </>
  );
};

export default DynamicTagBlogs;
