import React, { useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import Abha from "../components/Abha/Abha";
import Footer from "../components/Footer/Footer";

const AbhaPage = () => {
  useEffect(() => {
    document.title = "ABHA | BrainAlive";
  }, []);
  return (
    <>
      <Navbar />
      <Abha />
      <Footer />
    </>
  );
};

export default AbhaPage;
