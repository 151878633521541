import React from "react";
import "./Privacy.css";
import privacy from "../../../assets/focii-youtube/privacy.png";
import lock from "../../../assets/focii-youtube/lock.png";
import arrow from "../../../assets/focii-youtube/arrow.png";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <section className="container">
      <div className="privacy-control-system">
        <h1>Keep control of your Privacy</h1>
        <div className="row my-5">
          <div className="col-lg-6 d-flex align-items-center justify-content-center">
            <img className="w-100 px-3" src={privacy} alt="" />
          </div>
          <div className="col-lg-6 d-flex align-items-center">
            <div>
              <img className="lock-img" src={lock} alt="" />
              <h5>Worried about privacy? You have complete control.!</h5>
              <p>
                You can enable or disable FOCII at any given time. Additionally,
                when FOCII is enabled, your webcam and microphone data never
                leaves your computer, unless you want to share it with us for
                additional rewards!
              </p>
              <Link to="/privacy">
                <a href="">
                  Read our Privacy Policies <img src={arrow} alt="" />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Privacy;
