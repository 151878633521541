import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import DownloadPageContent from "../../components/DownloadComp/DownloadPageContent";


const DownloadExtension = ()=>{
    return(<>
        <Navbar/>
        <DownloadPageContent/>
        <Footer/>
    </>)
}

export default DownloadExtension;