import React from "react";
import "./YoutubeHeader.css";
import extension from "../../../assets/focii-youtube/extension-img.png";
import banner from "../../../assets/focii-youtube/banner-img.png";

const YoutubeHeader = () => {

  const redirectPluginPage = ()=>{
    window.open('https://chromewebstore.google.com/detail/focii-youtube/jamlalnkhijfjgbohdakdfgdnidoghca', '_blank');
  }
  return (
    <section className="youtube-hero-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 ">
            <div className="youtube-hero-left-section">
              <h1>
                Earn <span className="transparent-text-color">Rewards</span>{" "}
                <br className="hide-for-mobile" /> while watching <br className="hide-for-mobile" />{" "}
                <span className="transparent-text-color">YouTube videos</span>
              </h1>
              <p>
                FOCII for YouTube helps you earn in crypto while you watch your
                YouTube video(s)
              </p>
              <button onClick={redirectPluginPage}>
                Add to Chrome, <span>it's free</span>
              </button>
              {/* For now we have removed the chrome web store ratings image */}
              {/* <img className="w-100" src={extension} alt="" />  */}
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center justify-content-center">
            <div>
              <img className="w-100" src={banner} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default YoutubeHeader;
