import React from "react";
import "./EarnMoreBanner.css";
import banner from "../../../assets/focii-youtube/banner-img.png";

const EarnMoreBanner = () => {
  const redirectPluginPage = ()=>{
    window.open('https://chromewebstore.google.com/detail/focii-youtube/jamlalnkhijfjgbohdakdfgdnidoghca', '_blank');
  }
  return (
    <section className="earnmore-section-bg">
      <div className="container">
        <div className="row earnmore-banner column-reverse-in-tab m-0 px-4">
          <div className="col-lg-7 d-flex align-items-center">
            <div>
              <h1>
                <span>Earn</span> more BAAI tokens WHILE <br className="hide-for-tab" /> watching your{" "}
                <span>favourite</span> <br className="hide-for-tab" /> <span>YouTube Video</span>
              </h1>
              <p>Try FOCII Chrome Extension now and earn BAAI tokens!</p>
              <button onClick={redirectPluginPage}>Add to Chrome, it's free</button>
            </div>
          </div>
          <div className="col-lg-5">
            <img className="w-100" src={banner} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EarnMoreBanner;
