import React, { useEffect } from "react";
import AniLogo from "../../assets/ani-logo.svg";
import EureMedi from "../../assets/Euro-medi.svg";
import YourMedia from "../../assets/your-media.svg";
import QuaMedia from "../../assets/qua-media.svg";
import midday from "../../assets/midday.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AOS from "aos";
import "aos/dist/aos.css";

const MediaCoverage = () => {
  const teamViews = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    items: 1,
    loop: true,
    responsive: {},
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div className="autumn">
      <div className="container">
        <OwlCarousel {...teamViews} className=" owl-carousel owl-theme">
          <div className="item">
            <a
              className="autumn-text"
              href="https://www.aninews.in/news/business/business/brainalive-founder-wins-nasa-impact-planet-awards20231120153902/"
              target="_blank"
            >
              “Dr Sujit Roy, Co Founder, BrainAlive received NASA IMPACT Planet
              Award 2023 for his outstanding contribution to the Geospatial AI
              Foundation Model project. Dr Sujit Roy has over 10 years of
              experience of R&D in Machine Learning and Computational
              Neuroscience in global research organizations like NASA Impact and
              University of Manchester.”
              <h3>-AniNews</h3>
            </a>
          </div>
          <div className="item">
            <a
              className="autumn-text"
              href="https://focii-analytics.s3.amazonaws.com/assets/baai/EuroMeds+AA'23.pdf#page=24"
              target="_blank"
            >
              “BrainAlive’s innovative approach capitalizes on the stroke’s
              impact on specific cerebral regions while retaining relatively
              intact affected limbs. By directing motor tasks that require the
              initiation or suppression of distinct neural signals, BCI can
              effectively induce and guide the process of brain plasticity among
              stroke survivors.”
              <h3>-AUTUMN ASSEMBLY 2023 ISSUE(EMSA)</h3>
            </a>
          </div>
          <div className="item">
            <a
              className="autumn-text"
              href="https://www.qualcomm.com/company/locations/india/design-in-india-program/design-in-india-challenge#2022"
              target="_blank"
            >
              “Abbas Mehdi and Gaurav Dubey’s BrainAlive aims to improve user
              engagement in online learning/webinars/meeting through detecting,
              reasoning and determining the causality of user engagement using
              data-driven innovative tech.”
              <h3>-Qualcomm Design in India Challenge (QDIC)</h3>
            </a>
          </div>
          <div className="item">
            <a
              className="autumn-text"
              href="https://yourstory.com/video/indian-deep-tech-story"
              target="_blank"
            >
              “BrainAlive’s AI based virtual assistant has the power of sight
              and the mind of a human to accurately track the user engagement
              and provide powerful insights.”
              <h3>-YourStory TV</h3>
            </a>
          </div>
          <div className="item">
            <a
              className="autumn-text"
              href="https://www.mid-day.com/brand-media/article/6-influential-business-leaders-of-2023-23324926"
              target="_blank"
            >
              Dr. Sujit Roy is a computer scientist and has over 10 years of
              experience in the AI/ML industry in global research organizations
              including NASA Impact, University of Manchester, etc. He is Co-
              founder of BrainAlive and leading the development of AI-Engine
              with data privacy using blockchain. He has been involved in
              designing vision based foundation models and brain-computer
              interface algorithms.
              <h3>-Mid-Day</h3>
            </a>
          </div>
        </OwlCarousel>

        <div className="logos-media">
          <div className="logo_items">
            <img src={AniLogo} />
            <img src={EureMedi} />
            <img src={YourMedia} />
            <img src={QuaMedia} />
            <img src={midday} />

            <img src={AniLogo} />
            <img src={EureMedi} />
            <img src={YourMedia} />
            <img src={QuaMedia} />
            <img src={midday} />

            <img src={AniLogo} />
            <img src={EureMedi} />
            <img src={YourMedia} />
            <img src={QuaMedia} />
            <img src={midday} />

            <img src={AniLogo} />
            <img src={EureMedi} />
            <img src={YourMedia} />
            <img src={QuaMedia} />
            <img src={midday} />
          </div>
          <div className="logo_items">
            <img src={AniLogo} />
            <img src={EureMedi} />
            <img src={YourMedia} />
            <img src={QuaMedia} />
            <img src={midday} />

            <img src={AniLogo} />
            <img src={EureMedi} />
            <img src={YourMedia} />
            <img src={QuaMedia} />
            <img src={midday} />

            <img src={AniLogo} />
            <img src={EureMedi} />
            <img src={YourMedia} />
            <img src={QuaMedia} />
            <img src={midday} />

            <img src={AniLogo} />
            <img src={EureMedi} />
            <img src={YourMedia} />
            <img src={QuaMedia} />
            <img src={midday} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaCoverage;
