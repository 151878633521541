import React from "react";
import "../../css/style.css";
import "../../css/responsive.css";
import BaaiLogo from "../../assets/baai-logo.png";
import telegram from "../../assets/footer/TelegramLogo.png";
import insta from "../../assets/footer/Instagram_logo.png";
import twitter from "../../assets/footer/x.png";
import linkedIn from "../../assets/footer/LinkedIn_logo.png";
import ScrollToTop from "react-scroll-to-top";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="pt-5 pb-5 footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-xs-12 about-company">
            <div className="bhi-logo">
              <Link to="/home" style={{ marginBottom: "2rem" }}>
                <img src={BaaiLogo} />
              </Link>
            </div>
            <div className="bhi-logo-socail">
              <a href="https://twitter.com/BrainAlive_AI" target="_blank">
                <img src={twitter} />{" "}
              </a>
              <a
                href="https://www.linkedin.com/company/brainaliveai"
                target="_blank"
              >
                <img src={linkedIn} />{" "}
              </a>
              <a
                href="https://www.instagram.com/brainalive_ai/"
                target="_blank"
              >
                <img src={insta} />{" "}
              </a>

              <a href="https://t.me/Braina_Live" target="_blank">
                <img src={telegram} />
              </a>
            </div>
            <p>© All rights reserved 2025 | BrainAlive Research Pvt. Ltd.</p>
          </div>
          <div className="col-lg-2 col-3   links">
            <h4 className="mt-lg-0 mt-sm-3">Products</h4>
            <ul className="m-0 p-0">
              <li>
                <Link to="/focii">FOCII</Link>
              </li>
              <li>
                <Link to="/bci">Headsets</Link>
              </li>
              <li>
                <Link to="/abha">ABHA</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-4 links">
            <h4 className="mt-lg-0 mt-sm-3">Company</h4>
            <ul className="m-0 p-0">
              <li>
                {" "}
                <Link to="/about">About BrainAlive</Link>
              </li>
              <li>
                {" "}
                <Link to="/science">Our Science</Link>
              </li>
              <li>
                <Link to="/blog"> BrainAlive Blog</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-5 links">
            <h4 className="mt-lg-0 mt-sm-3">Support & Legal</h4>
            <ul className="m-0 p-0">
              <li>
                {" "}
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li>
                {" "}
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                {" "}
                <Link to="/terms">Terms and Conditions</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div />
        {/* <ScrollToTop
          className="owl-carousel-style"
          smooth
          component={<p className="scroll-top">Scroll Top</p>}
        /> */}
      </div>
    </footer>
  );
};

export default Footer;
