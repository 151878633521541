import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./TagRelatedBlogs.css";
import "../../Blog/Blogs/Blogs.css";
import { tagBlogData } from "../../Blog/TagRelatedBlogData/Data";
import userService from "../../../services/userServices";
import { LoadingContext } from "../../../App";

const TagRelatedBlogs = () => {
  const { setLoading } = useContext(LoadingContext);
  const { tag } = useParams();
  const [statickArticles, setStatickArticles] = useState([]);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    getBlogData();
    setStatickArticles(tagBlogData);
  }, [tag]);

  const getBlogData = async () => {
    setLoading(true);
    try {
      const response = await userService.getAllBlogs();
      if (response.error) {
        alert(response?.error?.message || "Something went worng!");
      } else {
        setArticles(response.data);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div className="dynamic-tag-related-blog primary-light-bg">
      <div className="container">
        <div className="search-for-heading">
          <p>
            {" "}
            <span className="search-result secondary-dark-text">
              Search results for:{" "}
            </span>{" "}
            <span className="secondary-dark-text" id="tag-title">
              {tag}
            </span>
          </p>
        </div>

        {articles.map((data, index) => (
          <div>
            <div>
              {data.tag.map(
                (element) =>
                  element.tags === tag && (
                    <div>
                      <div className="row text-white blogs-margin-top blogs-div mt-5">
                        <div className="col-lg-3 col-md-4 col-sm-12 d-flex align-items-center">
                          <Link
                            to={`/blog/${data.blogTitle.replace(/\s+/g, "_")}`}
                            style={{ textDecoration: "none" }}
                          >
                            <div className="blog-card-img-overflow">
                              <img
                                className="blog-card-img"
                                src={data.coverImg}
                                alt=""
                              />
                            </div>
                           <div className="show-in-mobile">
                           <img className="w-100" src={data.coverImg} alt="" />
                           </div>
                          </Link>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-12 d-flex align-items-center main-blog-card-text">
                          <div className="blog-card-text">
                            <Link
                              to={`/blog/${data.blogTitle.replace(
                                /\s+/g,
                                "_"
                              )}`}
                              style={{ textDecoration: "none" }}
                            >
                              <h4 className="blog-title-text black-gray">
                                {data.blogTitle}
                              </h4>{" "}
                              <div className="read-more-overflow">
                                <p className="read-more-text black-gray">
                                  {data.blogContent[0].description}
                                </p>
                              </div>
                              <p className="blog-read-more">Read more...</p>
                            </Link>

                            <div className="d-flex justify-content-between mt-4">
                              <div className="date-and-tag-flex">
                                <div className="date-div">
                                  <p style={{ color: "gray" }} className="date">
                                    {data.date}
                                  </p>{" "}
                                  <strong
                                    style={{ color: "gray" }}
                                    className="date-dot"
                                  >
                                    .
                                  </strong>{" "}
                                  <p
                                    style={{ color: "gray" }}
                                    className="date read-time"
                                  >
                                    10 min read
                                  </p>
                                  <strong className="blog-bottom-dot">.</strong>{" "}
                                </div>
                                <div className="blog-buttons">
                                  {data.tag.slice(0, 6).map((data, index) => (
                                    <Link to={`/blogs/tag/${data.tags}`}>
                                      <button
                                        className={`tag-btn-${index + 1}`}
                                      >
                                        <small>{data.tags}</small>
                                      </button>
                                    </Link>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        ))}

        {statickArticles.map((data, index) => (
          <div>
            <div>
              {data.tag.map(
                (element) =>
                  element.tags === tag && (
                    <div>
                      <div className="row text-white blogs-margin-top blogs-div mt-5">
                        <div className="col-md-3 col-sm-12 d-flex align-items-center">
                          <Link
                            to={data.link}
                            style={{ textDecoration: "none" }}
                          >
                            <div className="blog-card-img-overflow">
                              <img
                                className="blog-card-img"
                                src={data.img}
                                alt=""
                              />
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-9 col-sm-12 d-flex align-items-center main-blog-card-text">
                          <div className="blog-card-text">
                            <Link
                              to={data.link}
                              style={{ textDecoration: "none" }}
                            >
                              <h4 className="blog-title-text black-gray">
                                {data.title}
                              </h4>{" "}
                              <div className="read-more-overflow">
                                <p className="read-more-text black-gray">
                                  {data.readMore}
                                </p>
                              </div>
                              <p className="blog-read-more">Read more...</p>
                            </Link>

                            <div className="d-flex justify-content-between mt-4">
                              <div className="date-and-tag-flex">
                                <div className="date-div">
                                  <p style={{ color: "gray" }} className="date">
                                    {data.date}
                                  </p>{" "}
                                  <strong
                                    style={{ color: "gray" }}
                                    className="date-dot"
                                  >
                                    .
                                  </strong>{" "}
                                  <p
                                    style={{ color: "gray" }}
                                    className="date read-time"
                                  >
                                    10 min read
                                  </p>
                                  <strong className="blog-bottom-dot">.</strong>{" "}
                                </div>
                                <div className="blog-buttons">
                                  {data.tag.map((data, index) => (
                                    <Link to={`/blogs/tag/${data.tags}`}>
                                      <button
                                        className={`tag-btn-${index + 1}`}
                                      >
                                        <small>{data.tags}</small>
                                      </button>
                                    </Link>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TagRelatedBlogs;
