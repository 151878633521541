import React, { createContext, useState } from "react";
import "./App.css";
import NavigationRouter from "./routes/routes";
import Loading from "./components/Loading/Loading";
import { BrowserRouter } from "react-router-dom";

export const LoadingContext = createContext();

function App() {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <LoadingContext.Provider value={{ loading, setLoading }}>
        {loading && <Loading />}
        <BrowserRouter>
          <NavigationRouter />
        </BrowserRouter>
      </LoadingContext.Provider>
    </>
  );
}

export default App;
