import React from "react";
import "./GetRewarded.css";
import pin from "../../../assets/focii-youtube/pin.png";
import watch from "../../../assets/focii-youtube/watch.png";
import earn from "../../../assets/focii-youtube/earn.png";
import step1 from '../../../assets/reward/step-1-pin-focii.webm'
import step2 from '../../../assets/reward/step-2-earn.webm'
import step3 from '../../../assets/reward/step-3-utilize.webm'

const GetRewarded = () => {
  return (
    <section style={{ background: "#F7F7F7" }} className="pb-5">
      <div className="get-rewarded-header">
        <h1>
          Get Rewarded in 3 Easy <br className="hide-for-tab" /> Steps
        </h1>
      </div>

      <div className="container">
        <div className="row steps-card mx-0 ">
          <div className="col-lg-6 px-4">
            {/* <img className="w-100" src={pin} alt="" /> */}
            <video
                className="img-fluid"
                autoPlay
                muted
                loop
                // controls
                preload="auto"
                style={{padding:"10px"}}
              >
                <source
                  src={step1}
                  type="video/webm"
                ></source></video>
          </div>
          <div className="col-lg-6 d-flex align-items-center">
            <div>
              <small>Step 1</small>
              <h5>Pin FOCII to your Browser</h5>
              <p>
                Add FOCII to your browser, locate the extension tab and select
                the pin icon beside "FOCII".
              </p>
            </div>
          </div>
        </div>

        <div className="row steps-card my-5 mx-0 column-reverse-in-tab">
          <div className="col-lg-6 d-flex align-items-center">
            <div>
              <small>Step 2</small>
              <h5>Watch & Engage with content </h5>
              <p>
                Click on FOCII extension, follow the on screen instructions to
                create an account and start earning rewards. BAAI analyses your
                eye movement along with your facial and vocal expressions to
                understand how you respond to any content.
              </p>
            </div>
          </div>
          <div className="col-lg-6 px-4">
          <video
                className="img-fluid"
                autoPlay
                muted
                loop
                // controls
                preload="auto"
              >
                <source
                  src={step2}
                  type="video/webm"
                ></source>
              </video>
          </div>
        </div>

        <div className="row mx-0 steps-card">
          <div className="col-lg-6 px-4">
          <video
                className="img-fluid"
                autoPlay
                muted
                loop
                // controls
                preload="auto"
              >
                <source
                  src={step3}
                  type="video/webm"
                ></source>
              </video>
          </div>
          <div className="col-lg-6 d-flex align-items-center">
            <div>
              <small>Step 3</small>
              <h5>Earn and Utilize Rewards</h5>
              <p>
                $BAAI can be applied to almost anything. Use them to directly
                access our products and services. Trade $BAAI over leading
                exchanges. <br />
                BAAI is flexible, transferable and very easy to use..!”
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetRewarded;
