import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import TagRelatedBlogs from "../../../components/Blogs/TagRelatedBlogs/TagRelatedBlogs";
import Footer from "../../../components/Footer/Footer";

const TagRelatedBlog = () => {
  return (
    <div>
      <Navbar />
      <TagRelatedBlogs />
      <Footer />
    </div>
  );
};

export default TagRelatedBlog;
