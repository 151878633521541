import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const TrackAnalytics = (RouterComponent) => {
  const track = (path) => {
    console.log("tracking", path.split("/")[1])
    if (path === "/") {
      path = "/home";
    }
    ReactGA.send({
      hitType: "pageview",
      page: path,
      title: path.split("/")[1],
    });
  };
  const WithAnalytics = (props) => {
    const location = useLocation();

    useEffect(() => {
      const page = location.pathname + location.search;
      track(page);
    }, [location]);

    return <RouterComponent {...props} />;
  };

  return WithAnalytics;
};

export default TrackAnalytics;
