import React, { useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import AboutUs from "../components/AboutUs/AboutUs";
import Footer from "../components/Footer/Footer";

const AboutPage = () => {
  useEffect(() => {
    document.title = "About | BrainAlive";
  }, []);
  return (
    <>
      <Navbar />
      <AboutUs />
      <Footer />
    </>
  );
};

export default AboutPage;
