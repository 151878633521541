import React from "react";
import "./Loading.css";
import { Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";

const Loading = () => {
  const antIcon = (
    <Loading3QuartersOutlined
      style={{
        fontSize: 70,
        color: "white ",
      }}
      spin
    />
  );

  return (
    <div className="loading-image-container">
      <Spin indicator={antIcon} />
    </div>
  );
};

export default Loading;
